import React from "react";
import { compose } from "redux";
import moment from 'moment-timezone';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import OTSClassItem from "./otsClassItem";
import { createOTSRoom } from "../../actions/actions";

const OTSClassDetails = (props) => {
  const { otsClasses } = props;
  let customData = otsClasses.filter(item => moment().format('YYYY-MM-DD') === moment(item.OTS.startDateTime).format('YYYY-MM-DD'))
  return (
    <>
      {otsClasses
        ? otsClasses.map((val, index) => (
          <OTSClassItem props={props} val={val} index={index} />
        ))
        : null}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    createOTSRoom: (data) => dispatch(createOTSRoom(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    loginData: state.loginData,
    otsClasses: state.otsClasses,
    classes: state.classes,
    pending: state.pending,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OTSClassDetails);
