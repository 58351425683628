import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import { Tabs, Button } from 'antd';
import Title from "../../components/Title/Title"
import NothingScreen from "../../components/Coaching/nothingScreen";
import CoachingCard from "../../components/Coaching/coachingCard";
import LoadingAnimation from '../../components/Loader/LoadingAnimation';
import { changeHeader, getAllOtsClasses } from "../../actions/actions";
import EditCoachClass from "../../components/Coaching/EditCoaching/editCoachClass";

const { TabPane } = Tabs;

const Coaching = (props) => {

    const { otsClasses, loginData, showLoader, handleCollapsable } = props
    const [selectedClass, setSelectedClass] = useState(null);

    useEffect(() => {
        const data = {
            token: loginData ? loginData.access : null,
            teacherId: loginData ? loginData.userID : null,
        };
        !otsClasses.length && props.getAllOtsClasses(data);
    }, []);

    const [openModal, setOpenModal] = useState(false);

    const handleChangeModal = (value) => {
        setOpenModal(value);
    };

    let classesParsedOTS = {};
    let activeOTSClasses = otsClasses ?? [];
    let sortedDates = [];

    activeOTSClasses.map(item => {
        let time = moment(item.OTS.startDateTime);
        const key = time.format('YYYYMMDD');
        if (!classesParsedOTS.hasOwnProperty(`${key}`)) {
            sortedDates.push({ momentDate: time, dateFormattedKey: key })
            classesParsedOTS[`${key}`] = [
                { classData: item }
            ];
        }
        else {
            classesParsedOTS[`${key}`].push({ classData: item })
        }
    });

    const handleChange = (value) => {
        setSelectedClass(value);
    };

    const handleClassChange = (value) => {
        setSelectedClass(value);
        props.changeHeader(false);
        handleCollapsable(false);
    };

    return (
        <>
            {selectedClass ? (
                <EditCoachClass selectedClass={selectedClass} handleClassChange={handleClassChange} />
            ) : (
                <div className="main-page-container">
                    <Title text={'Coaching'} index={1} />

                    {!showLoader ?

                        <div className="coaching-tab">
                            <Tabs defaultActiveKey="1" >
                                <TabPane tab="UPCOMING" key="1">
                                    {
                                        otsClasses[0] ?
                                            <CoachingCard handleChange={handleChange} OTSData={classesParsedOTS} handleCollapsable={handleCollapsable} />
                                            :
                                            <NothingScreen />
                                    }
                                </TabPane>
                                <TabPane tab="PAST" key="2">
                                    <NothingScreen />
                                </TabPane>
                            </Tabs>
                        </div>

                        :
                        <LoadingAnimation />
                    }
                </div>
            )}
                        </>

            )}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllOtsClasses: (data) => dispatch(getAllOtsClasses(data)),
        changeHeader: (data) => dispatch(changeHeader(data)),
    };
};

const mapStateToProps = (state) => {
    return {
        loginData: state.loginData,
        otsClasses: state.otsClasses,
        showLoader: state.showLoader
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Coaching);
