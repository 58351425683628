import React from "react";
import LocationMap from "../Location/locationMap";

const LocationInformation = (props) => {

    const { data } = props;

    return (
        <div style={{ padding: '12px 22px 12px 22px' }}>
            {Object.keys(data.country).map((key, index) => (
                <div style={{ display: 'flex', marginBottom: 10, marginTop: 10 }}>
                    <span className='location-values' style={{ width: '15%' }}>{index + 1}</span>
                    <span className='location-values' style={{ width: '80%' }}>{key}</span>
                    <span className='location-values' >{data.country[key]}</span>
                </div>
            ))}
            {/* <div style={{ marginTop: 20 }}>
                        <LocationMap />
                    </div> */}
        </div>
    );
};

export default LocationInformation;