import React from 'react';


const Statements = (props) => {

    const { data } = props;

    return (
        <>
            <div className='statement-card'>
                <div className='statement-card-dateamount'>
                    <span className='statement-card-date'>
                        {data.date}
                    </span>
                    <span className='statement-card-amount'>
                        ${data.amount}
                    </span>
                </div>
                <div className='statement-card-pdfcontainer'>
                    <span className='statement-card-pdf'>
                        View
                    </span>
                </div>
            </div>
            <div className="line-divider-statement" style={{ marginTop: 20, marginBottom: 20 }} ></div>
        </>
    )
}

export default Statements;