import React, { useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from 'moment'

import { Button } from "antd";
import HighChartGraph from "./highChartGraph";
import NoDataGraph from "../../assets/img/noGraph.svg";
import NoDataGraphMobile from "../../assets/img/noGraphMobile.svg";
import MonthlyNoDataGraph from "../../assets/img/monthlyNoDataGraph.svg";
import MonthlyNoDataGraphMobile from "../../assets/img/monthlyNoDataGraphMobile.svg";

const ViewsGraph = (props) => {

    const { teacherPerformanceData } = props;
    const initialData = {
        firstDataName: "",
        firstDataColor: "#1d615a",
        firstData: [],
        secondDataName: "",
        secondDataColor: "#f6c2b6",
        secondData: [],
        thirdDataName: "",
        thirdDataColor: "#bd9a5f",
        thirdData: [],
        xAxisData: []
    }
    const [monthlyGraph, setMonthlyGraph] = useState(false);
    const [dataDaily, setDataDaily] = useState(initialData);
    const [dataMonthly, setDataMonthly] = useState(initialData);

    useEffect(() => {
        let paidDailyData = [];
        let shortDailyData = [];
        let previewDailyData = [];
        let xAxisDailyData = [];
        let paidMonthlyData = [];
        let shortMonthlyData = [];
        let previewMonthlyData = [];
        let xAxisMonthlyData = [];
        if (teacherPerformanceData.views) {

            Object.keys(teacherPerformanceData.views.month).map(function (key, index) {
                paidMonthlyData.push(teacherPerformanceData.views.month[key].paid);
                shortMonthlyData.push(teacherPerformanceData.views.month[key].short);
                previewMonthlyData.push(teacherPerformanceData.views.month[key].preview);
                xAxisMonthlyData.push(key);
            });
            setDataMonthly(Object.assign({}, dataMonthly,
                { firstData: paidMonthlyData },
                { secondData: shortMonthlyData },
                { thirdData: previewMonthlyData },
                { xAxisData: xAxisMonthlyData }
            ));

            Object.keys(teacherPerformanceData.views.week).map(function (key, index) {
                paidDailyData.push(teacherPerformanceData.views.week[key].paid);
                shortDailyData.push(teacherPerformanceData.views.week[key].short);
                previewDailyData.push(teacherPerformanceData.views.week[key].preview);
                xAxisDailyData.push(moment(key).format('ddd'));
            });
            setDataDaily(Object.assign({}, dataDaily,
                { firstData: paidDailyData },
                { secondData: shortDailyData },
                { thirdData: previewDailyData },
                { xAxisData: xAxisDailyData }
            ));
        }
    }, [teacherPerformanceData]);

    const toggleGraphViewDaily = () => {
        setMonthlyGraph(false);
    }

    const toggleGraphViewMonthly = () => {
        setMonthlyGraph(true);
    }

    return (
        <>
            <div className="views-graph" >
                <div className="views-graph-buttons">
                    <div className="view-button">
                        <Button
                            style={{
                                color: monthlyGraph ? "#262626" : "#ffffff",
                                fontSize: 16,
                                backgroundColor: monthlyGraph ? "transparent" : "#1d615a",
                                borderColor: monthlyGraph ? "transparent" : "#1d615a"
                            }}
                            type="primary"
                            htmlType="submit"
                            className="view-button-inside"
                            onClick={toggleGraphViewDaily}
                        >
                            Daily
                        </Button>
                    </div>
                    <div className="view-button" style={{ marginLeft: 9 }}>
                        <Button
                            style={{
                                color: monthlyGraph ? "#ffffff" : "#262626",
                                fontSize: 16,
                                backgroundColor: monthlyGraph ? "#1d615a" : "transparent",
                                borderColor: monthlyGraph ? "#1d615a" : "transparent"
                            }}
                            type="primary"
                            htmlType="submit"
                            className="view-button-inside"
                            onClick={toggleGraphViewMonthly}
                        >
                            Monthly
                        </Button>
                    </div>
                </div>
                <div className="views-graph-label-container" style={{ alignItems: "center", display: "flex" }}>
                    <div style={{ display: "flex", alignItems: 'center' }}>
                        <div className="graph-label-reactangle"
                            style={{ backgroundColor: "#1d615a" }}
                        ></div>
                        <span className="graph-label">
                            Paid
                        </span>
                    </div>
                    <div style={{ display: "flex", alignItems: 'center' }}>
                        <div className="graph-label-reactangle"
                            style={{ backgroundColor: "#f6c2b6" }}
                        ></div>
                        <span className="graph-label" >
                            Shorts
                        </span>
                    </div>
                    <div style={{ display: "flex", alignItems: 'center' }}>
                        <div className="graph-label-reactangle"
                            style={{ backgroundColor: "#bd9a5f" }}
                        ></div>
                        <span className="graph-label" >
                            Previews
                        </span>
                    </div>
                </div>
            </div>
            <div style={{ marginTop: window.innerWidth < 760 ? 30 : 73 }}>
                {monthlyGraph ? (
                    <>
                        {dataMonthly.firstData.length ? (
                            <>
                                <HighChartGraph
                                    key={monthlyGraph ? 'monthly-graph' : 'daily-graph'}
                                    height={window.innerWidth < 760 ? 200 : 400}
                                    linesWidth={true}
                                    linesWidth={1}
                                    data={monthlyGraph ? dataMonthly : dataDaily}
                                    pointPlacement={'on'}
                                />
                            </>
                        ) : (
                            <img src={window.innerWidth < 760 ? MonthlyNoDataGraphMobile : MonthlyNoDataGraph} alt='' width="100%" height="100%" />
                        )}
                    </>
                ) : (
                    <>
                        {dataDaily.firstData.length ? (
                            <>
                                <HighChartGraph
                                    key={monthlyGraph ? 'monthly-graph' : 'daily-graph'}
                                    height={window.innerWidth < 760 ? 200 : 400}
                                    linesWidth={true}
                                    linesWidth={1}
                                    data={monthlyGraph ? dataMonthly : dataDaily}
                                    pointPlacement={'on'}
                                />
                            </>
                        ) : (
                            <img src={window.innerWidth < 760 ? NoDataGraphMobile : NoDataGraph} alt='' width="100%" height="100%" />
                        )}
                    </>
                )}


            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        teacherPerformanceData: state.teacherPerformanceData,
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, null))
    (ViewsGraph);
