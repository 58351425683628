import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import TestWebcam from "../../components/TestScreen/testWebcam";
import TestIconFlow from "../../components/TestScreen/testIconFlow";
import TestAudioVolume from "../../components/TestScreen/testAudioVolume";
import TestScreenButtons from "../../components/TestScreen/testScreenButtons";
import TestNetworkConnection from "../../components/TestScreen/testNetworkConnection";

const AudioVideoTest = (props) => {

  const { audioTestCompleted, networkTestCompleted } = props;

  return (
    <div>
      <div className="testscreen-heading">
        <span>
          Let's perform some tests before you start the class
        </span>
      </div>
      <div className="test-mainscreen">
        <div>
          <TestIconFlow />
        </div>
        {
          !audioTestCompleted ? (
            <div className="test-audio-section">
              <TestAudioVolume />
            </div>
          ) : null
        }
        {
          audioTestCompleted && !networkTestCompleted && (
            <div className="test-network-section">
              <TestNetworkConnection />
            </div>
          )
        }
        {
          audioTestCompleted && networkTestCompleted && (
            <div className="test-webcam-section">
              <TestWebcam />
            </div>
          )
        }
      </div>
      <div className="test-buttons">
        <TestScreenButtons />
      </div>
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    audioTestCompleted: state.audioTestCompleted,
    networkTestCompleted: state.networkTestCompleted,
  };
};
export default compose(
  withRouter,
  connect(mapStateToProps, null)
)(AudioVideoTest);
