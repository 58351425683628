import React, { useEffect, useRef } from 'react';

import CrossIcon from "../../assets/img/icons/cross.png";
import BackArrowIcon from "../../assets/img/icons/backarrow.png";


const ZoomDialog = (props) => {
  let { closeDetailModal, closeZoom, audioRef, videoTracks, identity } = props;

  const videoReff = useRef();

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoReff.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  const wrapperStyles = {
    display: 'flex',
    position: 'fixed', /* Stay in place */
    zIndex: 1, /* Sit on top */
    left: 0,
    top: 0,
    width: '100vw', /* Full width */
    height: '100vh', /* Full height */
    overflow: 'auto', /* Enable scroll if needed */
    backgroundColor: 'rgba(0,0,0,0.4)', /* Black w/ opacity */
  }
  const participantStyle = {
    position: "relative",
    width: 608,
    height: 342,
    margin: 'auto'
  };

  return (
    <>
      <div onClick={closeDetailModal} style={wrapperStyles}>
        <div style={participantStyle}>
          <div className="cross-icon-modal"
            onClick={(e) => { e.stopPropagation(); closeDetailModal(); closeZoom() }}
          >
            <img
              style={{ width: "100%", cursor: "pointer" }} alt="" src={CrossIcon} />
          </div>
          <div className="back-icon-modal"
            onClick={(e) => { e.stopPropagation(); closeZoom() }}
          >
            <img
              style={{ width: "100%" }} alt="" src={BackArrowIcon} />
          </div>
          <div
            className="identity">{identity}</div>
          <video
            onClick={(e) => { e.stopPropagation(); }}
            style={{ boxShadow: "none", borderRadius: 5 }} ref={videoReff} autoPlay={true} />
          <audio
            onClick={(e) => { e.stopPropagation(); }}
            ref={audioRef} autoPlay={true} muted={false} />
        </div>
      </div>
    </>
  );
};

export default (ZoomDialog);
