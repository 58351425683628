import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Tabs } from "antd";

import { withRouter } from "react-router-dom";
import Title from "../../components/Title/Title";
import { getAllSimpleClasses } from "../../actions/actions";
import CalendarView from '../../components/Calendar/calendar';
import SimpleClasses from '../../components/SimpleClasses/simpleClasses';
import SimpleClassesTitle from "../../components/SimpleClasses/simpleClassesTitle";
import SimpleClassesDescription from "../../components/SimpleClasses/simpleClassesDescription";
import LoadingAnimation from "../../components/Loader/LoadingAnimation";


const { TabPane } = Tabs;

const Classes = (props) => {

    const { simpleClasses, loginData } = props;

    useEffect(() => {
        const data = {
            token: loginData ? loginData.access : null,
            teacherId: loginData ? loginData.userID : null,
        };
        !simpleClasses.scheduled && props.getAllSimpleClasses(data);
    }, []);

    return (
        <div
            className='main-page-container'
        >
            <Title text={'Classes'} index={3} />

            {simpleClasses.scheduled ?

                <Tabs defaultActiveKey="2" tabBarGutter={45} className="classes-tab">
                    <TabPane tab="PLANNED" key="1" disabled>
                        <SimpleClassesTitle
                            title={'Planned Classes'}
                            count={simpleClasses.scheduled.length}
                        />

                        <SimpleClassesDescription description={'You can now plan your classes three months in advance.'} />
                        <div style={{ marginTop: 48 }}>

                            {simpleClasses.scheduled.length ?
                                (
                                    simpleClasses.scheduled.map((val) => (
                                        <SimpleClasses data={val} />
                                    ))
                                ) : (
                                    <span class='perfomance-item-title'
                                        style={{ justifyContent: 'center', color: '#929292', marginTop: -30 }}>
                                        Waiting for data
                                    </span>
                                )
                            }
                        </div>
                    </TabPane>
                    <TabPane tab="PUBLISHED" key="2">
                        <SimpleClassesTitle
                            title={'Published Classes'}
                            count={simpleClasses.published.length}
                        />

                        <SimpleClassesDescription description={'These classes are currently streaming on our platform.'} />
                            <div style={{ marginTop: 24 }}>
                                {simpleClasses.published.length ?
                                    (
                                        simpleClasses.published.map((val) => (
                                            <SimpleClasses
                                                data={val}
                                                status={'published'}
                                            />
                                        ))
                                    ) : (
                                        <span class='perfomance-item-title'
                                            style={{ justifyContent: 'center', color: '#929292', marginTop: 60 }}>
                                            Waiting for data
                                        </span>
                                    )
                                }
                            </div>
                    </TabPane>
                    <TabPane tab="EXPIRED" key="3" disabled>
                        <SimpleClassesTitle title={'Expired Classes'} count={simpleClasses.expired.length} />

                        <SimpleClassesDescription description={'These classes are currently streaming on our platform.'} />
                        <div style={{ marginTop: 48 }}>
                            {simpleClasses.expired.length ?
                                (
                                    simpleClasses.expired.map((val) => (
                                        <SimpleClasses
                                            data={val}
                                            status={'expired'}
                                        />
                                    ))
                                ) : (
                                    <span class='perfomance-item-title'
                                        style={{ justifyContent: 'center', color: '#929292', marginTop: -30 }}>
                                        Waiting for data
                                    </span>
                                )
                            }
                        </div>
                    </TabPane>
                </Tabs>

                :
                <LoadingAnimation />
            }

        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllSimpleClasses: (data) => dispatch(getAllSimpleClasses(data)),
    };
};

const mapStateToProps = (state) => {
    return {
        loginData: state.loginData,
        simpleClasses: state.simpleClasses
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Classes);