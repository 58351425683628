import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Video from "twilio-video";
import { compose } from "redux";

import Participant from "./Participant";
import {
  playVideo,
  endClass,
  newParticipantConnected,
  videoPlayed,
  addParticipant,
  removeParticipant,
  storeRoomData,
  updateRulesforNewParticipant,
  endOTSRoom,
} from "../../actions/actions";

const Room = (props) => {
  const {
    returnToLobby,
    createdClass,
    loginData,
    roomName,
    token,
    isVideoPLayed,
    exitRoom,
    isTeacherMuted,
    muteStudents,
    otsRoomData
  } = props;
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  let videoPlayedT = props.isVideoPLayed;

  useEffect(() => {
    const participantConnected = (participant) => {
      props.addParticipant(participant.sid);
      props.updateRulesforNewParticipant(participant.sid);
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };

    const participantDisconnected = (participant) => {
      props.removeParticipant(participant.sid);
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );
    };

    const reconnected = (participant) => {
      videoPlayedT = !videoPlayedT;
      props.videoPlayed(videoPlayedT);
    };

    Video.connect(token, {
      name: roomName,
    }).then((room) => {
      setRoom(room);
      props.storeRoomData({
        roomId: room.sid,
        roomLocalParticipantId: room.localParticipant.sid,
      });
      room.on("participantConnected", participantConnected);
      room.on("participantDisconnected", participantDisconnected);
      room.on("reconnected", reconnected);
      room.participants.forEach(participantConnected);
    });

    return () => {
      setRoom((currentRoom) => {
        if (currentRoom && currentRoom.localParticipant.state === "connected") {
          currentRoom.localParticipant.tracks.forEach(function (
            trackPublication
          ) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [roomName, token]);

  useEffect(() => {
    props.newParticipantConnected(participants.length);
  }, [participants]);

  const remoteParticipants = participants.map((participant, index) => (
    <Participant
      index={index}
      // participantLength={participants.length}  
      key={participant.sid}
      participant={participant}
      localParticipantId={room.localParticipant.sid}
      localParticipantTracks={room.localParticipant}
      localAudioId={room.localParticipant.audioTracks}
      roomId={room.sid}
    />
  ));

  useEffect(() => {
    if (room) {
      room.disconnect();
      returnToLobby();
      const endClassData = {
        otsId: otsRoomData.OTS.roomID,
        token: loginData.access,
      };
      props.endOTSRoom(endClassData);
      props.playVideo(false);
    }
  }, [exitRoom]);
  return (
    <div className="room">
      <div className="participants"
      >
        {room ? (
          <Participant
            key={room.localParticipant.sid}
            participant={room.localParticipant}
            localParticipantSid={room.localParticipant.sid}
            localParticipant={true}
            localAudioId={room.localParticipant.audioTracks}
          />
        ) : null}
        {remoteParticipants}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    endClass: (data) => dispatch(endClass(data)),
    playVideo: (data) => dispatch(playVideo(data)),
    videoPlayed: (data) => dispatch(videoPlayed(data)),
    storeRoomData: (data) => dispatch(storeRoomData(data)),
    addParticipant: (data) => dispatch(addParticipant(data)),
    removeParticipant: (data) => dispatch(removeParticipant(data)),
    endOTSRoom: (data) => dispatch(endOTSRoom(data)),
    newParticipantConnected: (data) => dispatch(newParticipantConnected(data)),
    updateRulesforNewParticipant: (data) =>
      dispatch(updateRulesforNewParticipant(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    roomData: state.roomData,
    exitRoom: state.exitRoom,
    loginData: state.loginData,
    createdClass: state.createdClass,
    muteStudents: state.muteStudents,
    isVideoPLayed: state.isVideoPLayed,
    isTeacherMuted: state.isTeacherMuted,
    otsRoomData: state.otsRoomData,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Room);
