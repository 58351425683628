import React, { useEffect } from 'react';
import { Select, Button } from 'antd';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import { cancelOTSClass } from '../../actions/actions';

const CancelClassModal = (props) => {

    const { handleChangeCancelModal, pending, otsClassCanceled, handleClassChange, selectedClass, loginData} = props;
    const { Option } = Select;

    useEffect(()=>{
        if(otsClassCanceled){
            handleChangeCancelModal();
            handleClassChange(false);
        }
    }, [otsClassCanceled]);

    const onChangeCanel = () => {
        const data = {
            token: loginData ? loginData.access : null,
            otsId: selectedClass ? selectedClass.classData.OTS.id : null
        };
        props.cancelOTSClass(data);
    };

    const wrapperStyles = {
        display: 'flex',
        position: 'fixed', /* Stay in place */
        zIndex: 1, /* Sit on top */
        left: 0,
        top: 0,
        width: '100vw', /* Full width */
        height: '100vh', /* Full height */
        overflow: 'auto', /* Enable scroll if needed */
        backgroundColor: 'rgba(0,0,0,0.4)', /* Black w/ opacity */
    }
    const participantStyle = {
        position: "relative",
        width: window.innerWidth > 766 ? 608 : 344,
        height: window.innerWidth > 766 ? 328 : 418,
        margin: 'auto',
        borderRadius: 5,
        backgroundColor: "white"
    };

    return (
        <div onClick={() => { handleChangeCancelModal() }} style={wrapperStyles}>
            <div className='modal' style={participantStyle} onClick={(e) => { e.stopPropagation(); }} >
                <span className='modal-heading' >
                    Are you sure you want to cancel this class?
                </span>
                <span className='modal-description'>
                    We understand that sometimes life gets in the way. We’ll
                    send a message to all attending students appologising for
                    the cancellation.
                </span>
                {/* <div className='modal-select'>
                    <span className='modal-subheading'>
                        Reason for cancelling
                    </span>
                    <Select defaultValue="lucy" style={{ width: 220 }} >
                        <Option value="jack">Wrong date</Option>
                        <Option value="lucy">Wrong time</Option>
                        <Option value="lucy">Wrong something</Option>
                    </Select>
                </div> */}
                <span className='modal-description'>
                    <div>
                        <span>
                            You can change the date and time upto 48 hour prior to the
                            class.{' '}
                        </span>
                        <span style={{ color: '#1d615a', textDecoration: 'underline' }}>
                            Change date and time.
                        </span>
                    </div>
                </span>
                <div className='modal-select'>
                    <Button className="modal-button" onClick={()=>{handleChangeCancelModal()}} >
                        Close
                    </Button>
                    <Button className="modal-button-cancel" loading={pending} onClick={()=>{onChangeCanel()}}>Cancel class</Button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
      pending: state.pending,
      loginData: state.loginData,
      otsClassCanceled: state.otsClassCanceled,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        cancelOTSClass: (data) => dispatch(cancelOTSClass(data)),
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
  )(CancelClassModal);