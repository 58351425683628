import React from 'react';
import mapboxgl from "mapbox-gl";
import ReactMapboxGl from 'react-mapbox-gl';
import { ReactMapboxGlCluster } from 'react-mapbox-gl-cluster';
import { data } from './data';
import 'mapbox-gl/dist/mapbox-gl.css';
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;


const Map = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoiZGlyZWN0Y29hY2giLCJhIjoiY2tydTkzMTlqMWlrcDJucG94eTc0YnI2eSJ9.jHsHaG2mSpIgATXo6Z8nNg'
});

const LocationMap = () => {

  const mapProps = {
    center: [-95.7129, 37.0902],
    zoom: [3],
    style: 'mapbox://styles/mapbox/streets-v8',
  };

  const getEventHandlers = () => {
    return {
      onClick: (properties, coords, offset) =>
        console.log(`Receive event onClick at properties: ${properties}, coords: ${coords}, offset: ${offset}`),
      onMouseEnter: (properties, coords, offset) =>
        console.log(`Receive event onMouseEnter at properties: ${properties}, coords: ${coords}, offset: ${offset}`),
      onMouseLeave: (properties, coords, offset) =>
        console.log(`Receive event onMouseLeave at properties: ${properties}, coords: ${coords}, offset: ${offset}`),
      onClusterClick: (properties, coords, offset) =>
        console.log(`Receive event onClusterClick at properties: ${properties}, coords: ${coords}, offset: ${offset}`),
    };
  }

  return (
    <div>
      <Map
        style="mapbox://styles/mapbox/light-v10"
        containerStyle={{
          height: window.innerWidth < 760 ? '20vh' : '35vh',
          width: '100%'
        }}
        center={[-95.7129, 37.0902]}
        zoom={[3]}
        className="location-map"
      >
        <ReactMapboxGlCluster
          className="map-cluster"
          clusterClassName={'custom-cluster'}
          pointClassName={'custom-pointer'}
          data={data}
          {...getEventHandlers()}
        />
      </Map>

    </div>
  );
};

export default LocationMap;