import React from 'react';

const Circle = (props) => {
      const { color, borderColor, circleHeight, circleWidth, circlePaddingTop, circleAlignSelf, circleMarginLeft } = props;
      return (

            <span style={{
                  height: circleHeight ? circleHeight : 20 ,
                  width: circleWidth ? circleWidth : 20,
                  backgroundColor: `${color}`,
                  borderRadius: "50%",
                  display: "inline-block",
                  borderStyle: "solid",
                  border: '1px solid',
                  borderColor: `${borderColor}`,//#1d615a
                  paddingTop: circlePaddingTop ? null : 10,
                  marginLeft: circleMarginLeft ? 2 : null,
                  alignSelf: circleAlignSelf ? circleAlignSelf : null
            }}></span>

      );
}

export default Circle;