import React from "react";
import { withRouter } from "react-router-dom";
import Webcam from "react-webcam";

import AlertTriangle from "../../assets/img/icons/alertIcon.svg";

const TestWebcam = () => {

    const webcamRef = React.useRef(null);

    const videoConstraints = {
        width: 634,
        height: 357,
        facingMode: "user"
    };

    return (
        <>
            <div className="webcam-main">
                <Webcam
                    className="test-webcam"
                    audio={false}
                    height={357}
                    ref={webcamRef}
                    width={634}
                    videoConstraints={videoConstraints}
                />
            </div>
            <div className="webcam-main" style={{ marginTop: 20 }}>
                <img rel="preload" width="23" src={AlertTriangle} as="image" />
                <div className="title-testicons" style={{}}>
                    <span style={{ marginLeft: 13 }}>Not seeing your webcam?</span>
                    <a className="webcam-link" style={{ marginLeft: 13, color: "#383838", fontWeight: "bold", textDecoration: "underline" }}>
                        Learn More
                    </a>
                </div>
            </div>
        </>
    );
};

export default withRouter(TestWebcam);
