import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import moment from 'moment';

import Title from '../../components/Title/Title';
import CalendarView from "../../components/Calendar/calendar";
import DateTimeline from "../../components/Calendar/dateTimeline";
import { getAllSimpleClasses } from "../../actions/actions";
import LoadingAnimation from "../../components/Loader/LoadingAnimation";


const Calendarr = (props) => {

  const { simpleClasses, loginData } = props;
  const date = new Date();
  const calendarRef = useRef(null)
  const [selectedDate, setSelectedDate] = useState(moment(date).format('DD-MM-YYYY'));


  useEffect(() => {
    const data = {
      token: loginData ? loginData.access : null,
      teacherId: loginData ? loginData.userID : null,
    };
    !simpleClasses.scheduled && props.getAllSimpleClasses(data);
  }, []);

  let classesParsed = {};
  let activeClasses = simpleClasses.published ?? [];
  let sortedDates = [];

  activeClasses.map(item => {
    let time = moment(item.class.startDateTime);
    const key = time.format('YYYYMMDD');
    if (!classesParsed.hasOwnProperty(`${key}`)) {
      sortedDates.push({ momentDate: time, dateFormattedKey: key })
      classesParsed[`${key}`] = [
        { status: item.class.status, content: item.class.name, classData: item.class, date: time }
      ];
    }
    else {
      classesParsed[`${key}`].push({ status: item.class.status, content: item.class.name, classData: item.class, date: time })
    }
  });

  const handleDateChange = (value) => {
    setSelectedDate(moment(value).format('DD-MM-YYYY'));
  }

  useEffect(() => {
    if (window.innerWidth > 1024 && calendarRef && calendarRef.current) {
      calendarRef.current.scroll({
        top: 0,
        behavior: "smooth"
      })
    }
  }, [selectedDate])


  return (
    <div
      ref={calendarRef}
      className='main-page-container'
    >

      <Title text={'Calendar'} />

      {simpleClasses.scheduled ?

        <div className='calendar'
          style={{
            display: 'flex',
          }}>
          <div className={'calendar-timeline wrapper-timeline'} >
            <DateTimeline selectedDate={selectedDate} simpleClasses={simpleClasses} classesParsed={classesParsed} />
          </div>

          <div className="calendar-view">
            <CalendarView
              handleDateChange={handleDateChange}
              simpleClasses={simpleClasses}
              selectedDate={selectedDate}
              classesParsed={classesParsed}
            />
          </div>
        </div>

        :
        <div style={{ paddingTop: "5%" }}>
          <LoadingAnimation />
        </div>
      }
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAllSimpleClasses: (data) => dispatch(getAllSimpleClasses(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    loginData: state.loginData,
    simpleClasses: state.simpleClasses
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Calendarr);