import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Layout, Row, Button, Menu, } from "antd";
import { Link, withRouter } from "react-router-dom";
import { PlusOutlined, BarsOutlined } from '@ant-design/icons';

import Logo from "../../assets/img/logo_light.png";
import { logoutUser } from "../../actions/actions";


const LoginHeader = (props) => {
  const { loginData } = props;
  const { Header } = Layout;

  const { SubMenu } = Menu;

  const logout = () => {
    props.logoutUser();
  }

  return (
    <>
      <Header className="header-new" style={{ height: 60 }}>
        <Row style={{ justifyContent: "space-between", }}>
          <Link to="/" >
            <div className="logo-new">
              <img
                className="logo-image-new"
                width="auto"
                height="auto"
                src={Logo}
                alt=""
              />
            </div>
          </Link>
          <div

          >
            {
              !loginData
                ? (
                  <div className="footer-button"
                    style={{
                      width: '100px',
                      height: 60,
                      display: 'flex',
                      margin: 0,
                      alignItems: 'center',
                      textAlign: "right",
                      justifyContent: "right",
                      marginRight: 20 
                    }}>
                    <a
                      href="https://app.bodymindlife.online/user/signup/"
                    >
                      <Button
                        style={{
                          backgroundColor: "#8fb594",
                          borderColor: "#8fb594",
                          width: "100px",
                          height: 40,
                          color: "black",

                        }}
                        type="primary"
                        htmlType="submit"
                        className="logout-button"
                      >
                        Signup
                      </Button>
                    </a>
                  </div>
                ) : (
                  <div className="footer-button"
                    style={{
                      width: '300px',
                      height: 60,
                      display: 'flex',
                      margin: 0,
                      alignItems: 'center',
                      textAlign: "right",
                      justifyContent: "right",

                    }}>
                    <Button
                      style={{
                        backgroundColor: "#8fb594",
                        borderColor: "#8fb594",
                        width: 100,
                        height: 40,
                        color: "black"
                      }}
                      type="primary"
                      htmlType="submit"
                      className="logout-button"
                      onClick={logout}
                    >
                      Logout
                    </Button>

                    <Link to="/profileandaccount" style={{ display: "flex", alignItems: "center", maxHeight: 60, padding: "0px 12px" }}>
                      <img src="https://www.w3schools.com/howto/img_avatar.png" alt="Avatar" className="avatar" />
                      <span className="userName" style={{ color: "white", marginLeft: "10px", whiteSpace: 'nowrap' }}>{loginData.userName}</span>
                    </Link>
                    {/* <BellOutlined style={{color:"white"}}/> */}

                    <div style={{
                      width: "56px",
                      height: "56px",
                      backgroundColor: "#1d615a",
                      float: "right",
                      marginRight: "2px",
                      marginLeft: "auto",
                      textAlign: "center",
                    }}>
                      <PlusOutlined style={{ color: "white", marginBottom: "inherit" }} />
                    </div>

                    <Menu mode="inline" className="MainMenu">
                      <SubMenu key="sub1" icon={<BarsOutlined />}>
                        <Menu.Item key="1">
                          <Link to="/profileandaccount" style={{ display: "flex", alignItems: "center", maxHeight: 60, padding: "0px 12px" }}>
                            <div style={{ marginLeft: "15%", }}>
                              <img src="https://www.w3schools.com/howto/img_avatar.png" alt="Avatar" className="avatar1" style={{
                                verticalAlign: "middle",
                                width: "32px",
                                height: "32px",
                                borderRadius: "50%",
                                marginLeft: "2%"
                              }} />
                              <span className="" style={{ color: "white", marginLeft: "10%" }}>{loginData.userName}</span>
                            </div>
                          </Link>

                        </Menu.Item>
                      </SubMenu>
                    </Menu>

                  </div>
                )

            }

          </div>
        </Row>

      </Header>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    tokenExpire: state.tokenExpire,
    loginData: state.loginData,
    isLogin: state.isLogin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: (data) => dispatch(logoutUser(data)),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(LoginHeader);