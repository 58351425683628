import React, { useState } from "react";
import { Card } from "antd";

import EditStatsTitle from "./editStatsTile";
import ThumbnailImage from "../../../assets/img/no-thumbnail-image.jpeg";
import { ReactComponent as BackArrow } from "../../../assets/img/icons/arrow_back.svg";


const EditCoachClass = (props) => {

    const { selectedClass, handleClassChange } = props;


    return (
        <div>

            <Card
                className='edit-card-main'
                cover={
                    <div className='edit-card-main'>
                        <img
                            alt="coaching-card-image"
                            src={ selectedClass.classData.OTS.thumbNailURL === "" ? ThumbnailImage : 
                            selectedClass.classData.OTS.thumbNailURL
                             }
                            height="100%"
                            width="100%"
                            style={{ objectFit: "cover" }}
                        />
                        <div className="edit-card-cover-overlay" >
                        </div>

                        <div className="coaching-card-image-title" >
                            <span className="coaching-card-image-title-heading">
                                {selectedClass.classData.OTS.name}
                            </span>
                            <span className="coaching-card-image-title-description">
                                {`${selectedClass.classData.OTS.category} · 
                                     ${parseInt((selectedClass.classData.OTS.videoDuration ?? 0) / 60)} min`}
                            </span>

                        </div>
                        <div className='edit-card-back-arrow' onClick={() => { handleClassChange(false) }} >
                            <BackArrow className='back-arrow-ots' />
                        </div>
                    </div>
                }
            >

            </Card>
            <div className="main-coaching-container">
                <EditStatsTitle selectedClass={selectedClass} handleClassChange={handleClassChange} />
            </div>

        </div>
    );
}

export default EditCoachClass;