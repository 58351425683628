import React, { useState } from "react";
import { Card } from "antd";
import { ReactComponent as ParticipantIcon } from "../../assets/img/icons/participants.svg";
import ThumbnailImage from "../../assets/img/no-thumbnail-image.jpeg";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { changeHeader } from "../../actions/actions";

const CoachingCard = (props) => {
    const { OTSData, handleChange, handleCollapsable } = props;
    const [viewCheck, setViewCheck] = useState(false)

    const handleCoachCardClick = (value) => {
        //setViewCheck(true)
        console.log('im in mapp', value);
        handleChange(value);
        handleCollapsable(true);
        props.changeHeader(true);
    }

    let coachDate = Object.keys(OTSData);
    coachDate = coachDate.sort((a, b) => a - b);

    let todayDate = moment().format('DD MMMM YYYY')
    let currentDateTitle = null
    let getCoachDate = (dateVal) => {
        let dateMoment = moment(dateVal, 'YYYYMMDD')
        let stringDate = dateMoment.format('DD MMMM YYYY')
        let titleReturn = null

        if (currentDateTitle !== stringDate) {
            currentDateTitle = stringDate;
            titleReturn = currentDateTitle;
        }
        return (
            <>
                {todayDate === currentDateTitle ?
                    <>
                        {titleReturn ?
                            <div className="coaching-card-date">
                                Today
                            </div>
                            : null
                        }
                    </>
                    :
                    <>
                        {titleReturn ?
                            <div className="coaching-card-date">
                                {moment(currentDateTitle).format('dddd, D MMM')}
                            </div>
                            : null
                        }
                    </>
                }
            </>
        )
    }


    return (
        <div className="coaching-card-container">


            {
                coachDate.map((dateVal, dateIndex) => (
                    <>
                        {getCoachDate(coachDate[dateIndex])}
                        <div className="flex-wrapper-coaching" style={{}}>
                            {
                                OTSData[dateVal].map((val) => (
                                    <div className="flex-wrapper-coaching-item-50 coaching-card" style={{}}>
                                        <div className="coaching-card-time">
                                            {moment(val.classData.startDateTime).format('hh:mm a ')}
                                            {moment(val.classData.endDateTime).format('- hh:mm a')}
                                        </div>
                                        <div className="coaching-card-image" onClick={() => { handleCoachCardClick(val) }}>
                                            <Card
                                                className="coaching-card-component"
                                                cover={
                                                    <img
                                                        alt="coaching-card-image"
                                                        src={
                                                            val.classData.OTS.thumbNailURL === "" ? ThumbnailImage : val.classData.OTS.thumbNailURL
                                                        }
                                                        height="216px"
                                                        width="385"
                                                    />
                                                }

                                            >
                                            </Card>
                                            <div className="card-cover-overlay-coaching"
                                            >
                                            </div>
                                            <div className="coaching-card-image-upper">
                                                <div className="coaching-card-image-upper-title">
                                                    {val.classData.OTS.name}
                                                </div>
                                                <div className="coaching-card-image-upper-category">
                                                    {val.classData.OTS.category === " " || val.classData.OTS.category === "" ? `${parseInt((val.classData.OTS.videoDuration ?? 0) / 60)} min` : `${val.classData.OTS.category} · ${parseInt((val.classData.OTS.videoDuration ?? 0) / 60)} min`}
                                                </div>
                                            </div>
                                            <div className="coaching-card-image-lower">
                                                <ParticipantIcon className="participant-icon" />
                                                <span className="coaching-card-image-lower-span">
                                                    {val.classData.Booked}/{val.classData.Max}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </>
                )
                )
            }
        </div>

    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeHeader: (data) => dispatch(changeHeader(data)),
    };
};

export default compose(
    withRouter,
    connect(null, mapDispatchToProps)
)(CoachingCard);