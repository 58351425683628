import React, { useState } from "react";
import { Button } from 'antd';


const AddSpotModal = (props) => {
    
    const [ count,setCount ] = useState(0);
    // const [ disable, setDisable ] = useState(true)
    const { handleChangeParticipantsModal } = props;
    const addClick = () => {
        if ( count !== 16 ){
            setCount(count+1)
        }
    }
    const subClick = () => {
        if (count !== 0 ){
            setCount(count-1)
        }
    }
    const wrapperStyles = {
        display: 'flex',
        position: 'fixed', /* Stay in place */
        zIndex: 1, /* Sit on top */
        left: 0,
        top: 0,
        width: '100vw', /* Full width */
        height: '100vh', /* Full height */
        overflow: 'auto', /* Enable scroll if needed */
        backgroundColor: 'rgba(0,0,0,0.4)', /* Black w/ opacity */
    }
    const participantStyle = {
        position: "relative",
        width: window.innerWidth > 766 ? 608 : 344,
        height: window.innerWidth > 766 ? 342 : 418,
        margin: 'auto',
        borderRadius: 5,
        backgroundColor: "white"
    };
    return (
        <div onClick={() => {handleChangeParticipantsModal()}} style={wrapperStyles} >
            <div className='modal' style={participantStyle} onClick={(e) => { e.stopPropagation(); }}>
                <span className='modal-heading' >
                    Add more spots
                </span>
                <span className='modal-description'>
                    You may increase the number of spots to accomodate also those on the waitlist. maximum number of students per class is 16.
                </span>
                <div className="modal-spots">
                    <span className={count === 0 ? "modal-signs modal-signs-disable" : "modal-signs" } onClick={subClick}>-</span>
                    <span className="modal-signs-amount">{count}</span>
                    <span className={count === 16 ? "modal-signs modal-signs-disable" : "modal-signs"} onClick={addClick}>+</span>
                </div>
                <div className='modal-select'>
                    <Button className="modal-button" onClick={() => {handleChangeParticipantsModal()}} >
                        Close
                    </Button>
                    <Button className="modal-button-cancel">Submit</Button>
                </div>
            </div>
        </div>
    );
}

export default AddSpotModal;