import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { Form, Input, Checkbox, Button, Row, Col } from "antd";

import { changeLoginState, postLoginData, storeLoggedInStatus } from "../../actions/actions";

const LoginForm = (props) => {
  const { isLogin, loginSuccessful, pending } = props;
  const history = useHistory();
  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 18,
    },
  };
  const tailLayout = {
    wrapperCol: {
      span: 24,
    },
  };

  const onFinish = (values) => {
    props.postLoginData(values);
    props.storeLoggedInStatus(values.remember);
  };

  useEffect(() => {
    if (isLogin === true && loginSuccessful === true) {
      history.push("/");
      props.changeLoginState(false);
    }
  }, [isLogin]);

  return (
    <div className="login-form">
      <p className="teacher-heading" style={{ color: "383838", marginBottom: 10 }}>Teacher Mission Control</p>
      <h1 className="login-heading" style={{ marginBottom: 10 }}>LOGIN</h1>
      <p className="teacher-heading-detail" style={{ marginBottom: 30 }}>Details</p>
      <Form
        {...layout}
        name="basic"
        initialValues={{
          remember: true,
        }}
        className="input-wrapper"
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please input a valid email!",
              type: "email",
            },
          ]}
        >
          <Input
            type="email"
            className="login-input"
            placeholder="Your Email *"
          />
        </Form.Item>
        <div></div>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input
            type="password"
            className="login-input login-password"
            placeholder="Your Password *" />
        </Form.Item>
        <Form.Item>
          <Row style={{ marginTop: 20 }}>
            <Col span={12}>
              <Form.Item name="remember" valuePropName="unchecked" noStyle>
                <Checkbox className="login-checkbox">Keep me logged in</Checkbox>
              </Form.Item>
            </Col>
            <Col style={{ alignSelf: "center" }} span={12}>
              <a
                className="login-form-forgot"
                style={{ float: "right" }}
                href=""
              >
                Learn More
              </a>
            </Col>

          </Row>
        </Form.Item>

        <Form.Item {...tailLayout} style={{ marginBottom: 6 }}>
          <Button
            style={{
              backgroundColor: "#8FB594",
              height: 55,
              borderColor: "#8FB594",
              color: "#383838",
              marginTop: 10
            }}
            loading={pending}
            type="primary"
            htmlType="submit"
            className="login-button-new"
          >
            Login
          </Button>
        </Form.Item>

        <Form.Item style={{ marginTop: 15, marginBottom: 35 }}>
          <a
            className="login-form-forgot"
            style={{ display: "block", textAlign: "center", color: "#383838" }}
            href="https://app.bodymindlife.online/user/forgot-password"
          >
            Forgot Password?
          </a>
        </Form.Item>

        <Form.Item>
          <Row>
            <Col span={12}>
              <p
                className="login-form-forgot"

                style={{
                  display: "block",
                  textAlign: "right",
                  color: "#383838",
                }}
              >
                Don't have an account?
              </p>
            </Col>
            <Col span={12}>
              <a
                className="login-form-forgot"
                style={{
                  display: "block",
                  textAlign: "center",
                  color: "#8FB594",
                  marginRight: 15
                }}
                href="https://app.bodymindlife.online/user/signup/"
              >
                Contact us to Sign Up
              </a>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    postLoginData: (data) => dispatch(postLoginData(data)),
    changeLoginState: (data) => dispatch(changeLoginState(data)),
    storeLoggedInStatus: (data) => dispatch(storeLoggedInStatus(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    pending: state.pending,
    isLogin: state.isLogin,
    loginData: state.loginData,
    loginSuccessful: state.loginSuccessful,
    // loginStatusCode: state.loginStatusCode,

  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(LoginForm);
