import React, { useState } from "react";
import { connect } from "react-redux";
import { Layout, Button } from "antd";
import { usePubNub } from "pubnub-react";

import PlayIcon from "../../assets/img/icons/play.png";
import PauseIcon from "../../assets/img/icons/pause.png";
import MicOnIcon from "../../assets/img/icons/micon.png";
import MicOffIcon from "../../assets/img/icons/micoff.png";
import EndClassIcon from "../../assets/img/icons/endclass.png";
import StartClassIcon from "../../assets/img/icons/startClass.png";
import {
  enableSpeakAll,
  exitRoom,
  loading,
  muteAllStudents,
  muteUnmuteTeacherMic,
  muteUnmuteVideoPlayer,
  playVideo,
  speakToAllStatus,
  startClass,
  syncVideo,
} from "../../actions/actions";

const StartClassFooter = (props) => {
  const {
    studentIds,
    localParticipantSid,
    otsRoomData,
    isPlayVideo,
    muteStudents,
    pending,
    roomJoined,
    exitRoom,
  } = props;
  const { Footer } = Layout;
  const [speakAll, setSpeakAll] = useState(false);
  const pubnub = usePubNub();
  const channelName = `OTS_TWL_${otsRoomData.OTS.roomID}`;

  const startClass = (event) => {
    if (event.detail) {
      props.loading(true);
      props.startClass(true);
    }
  };

  const endClass = (event) => {
    if (event.detail) {
      props.setExitRoom(!exitRoom);
    }
  };

  const speakToAll = (event) => {
    if (speakAll && event.detail) {
      const data = {
        participants: studentIds,
        roomId: otsRoomData.Room.sid,
        teacherId: localParticipantSid,
        type: "exclude",
      };
      props.enableSpeakAll(data);
      pubnub.publish({
        channel: channelName,
        message: {
          statusCode: 100,
        },
      });
      props.muteUnmuteTeacherMic(false);
      setSpeakAll(false);
      props.speakToAllStatus(false);
    } else {
      const data = {
        participants: studentIds,
        roomId: otsRoomData.Room.sid,
        teacherId: localParticipantSid,
        type: "include",
      };
      props.enableSpeakAll(data);
      pubnub.publish({
        channel: channelName,
        message: {
          statusCode: 101,
        },
      });
      props.muteUnmuteTeacherMic(true);
      setSpeakAll(true);
      props.speakToAllStatus(true);
      props.muteAllStudents(false);
    }
  };

  const playPauseVideo = (event) => {
    if (event.detail) {
      props.playVideo(!isPlayVideo);
    }
  };

  const unMuteAllStudents = (event) => {
    if (event.detail) {
      if (muteStudents) {
        props.muteUnmuteTeacherMic(false);
        const data = {
          participants: studentIds,
          roomId: otsRoomData.Room.sid,
          teacherId: localParticipantSid,
          type: "exclude",
        };
        props.enableSpeakAll(data);
        props.playVideo(true);
        props.muteUnmuteVideoPlayer(false);
        props.muteAllStudents(!muteStudents);
        pubnub.publish({
          channel: channelName,
          message: {
            statusCode: 104,
          },
        });
      } else {
        props.muteUnmuteTeacherMic(true);
        const data = {
          participants: studentIds,
          roomId: otsRoomData.Room.sid,
          teacherId: localParticipantSid,
          type: "include",
        };
        props.enableSpeakAll(data);
        props.playVideo(false);
        props.muteUnmuteVideoPlayer(true);
        props.muteAllStudents(!muteStudents);
        props.speakToAllStatus(false);
        setSpeakAll(false);
        pubnub.publish({
          channel: channelName,
          message: {
            statusCode: 103,
          },
        });
      }
    }
  };

  return (
    <Layout className="layout" style={{ background: "none" }}>
      <Footer style={{ background: "none", paddingBottom: 10 }}>
        <div
          className="button-wrapper"
          style={{ float: "right", display: "flex", flexWrap: "wrap", justifyContent: "center" }}
        >
          <div className="footer-button" style={{}}>
            <Button
              style={{
                backgroundColor: "#ECEEEE",
                borderColor: "#ECEEEE",
                color: "black",
                width: 140,
              }}
              type="primary"
              htmlType="submit"
              className="login-button"
              onClick={playPauseVideo}
            >
              <img
                style={{ width: 13, marginBottom: 2, marginRight: 9 }}
                src={isPlayVideo ? PauseIcon : PlayIcon}
                alt=""
              />
              {isPlayVideo ? "Pause Video" : "Play Video "}
            </Button>
          </div>

          <div className="footer-button" style={{ width: 140 }}>
            <Button
              style={{
                backgroundColor: speakAll ? "#8FB594" : "#ECEEEE",
                borderColor: speakAll ? "#8FB594" : "#ECEEEE",
                color: speakAll ? "white" : "black",
              }}
              type="primary"
              htmlType="submit"
              className="login-button"
              onClick={speakToAll}
            >
              <img
                style={{ width: 13, marginBottom: 2, marginRight: 9 }}
                src={speakAll ? MicOnIcon : MicOffIcon}
                alt=""
              />
              Speak to All
            </Button>
          </div>

          <div className="footer-button" style={{ width: 147 }}>
            <Button
              style={{
                backgroundColor: muteStudents ? "#8FB594" : "#ECEEEE",
                borderColor: muteStudents ? "#8FB594" : "#ECEEEE",
                color: muteStudents ? "white" : "black",
              }}
              type="primary"
              htmlType="submit"
              className="login-button"
              onClick={unMuteAllStudents}
            >
              <img
                style={{ width: 13, marginBottom: 2, marginRight: 9 }}
                src={muteStudents ? MicOnIcon : MicOffIcon}
                alt=""
              />
              Hear from All
            </Button>
          </div>
          {/* )} */}

          {roomJoined ? (
            <div className="footer-button" style={{ width: 130 }}>
              <Button
                style={{
                  backgroundColor: "#FF3B30",
                  borderColor: "#FF3B30",
                }}
                type="primary"
                htmlType="submit"
                className="login-button"
                onClick={endClass}
                loading={pending}
              >
                <img
                  style={{ width: 15, marginBottom: 2, marginRight: 9 }}
                  src={EndClassIcon}
                  alt=""
                />
                End Class
              </Button>
            </div>
          ) : (
            <div className="footer-button" style={{ width: 130 }}>
              <Button
                style={{
                  backgroundColor: "#8FB594",
                  borderColor: "#8FB594",
                }}
                loading={pending}
                type="primary"
                htmlType="submit"
                className="login-button"
                onClick={startClass}
              >
                <img
                  style={{ width: 15, marginBottom: 2, marginRight: 9 }}
                  src={StartClassIcon}
                  alt=""
                />
                Start Class
              </Button>
            </div>
          )}
        </div>
      </Footer>
    </Layout>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    loading: (data) => dispatch(loading(data)),
    syncVideo: (data) => dispatch(syncVideo(data)),
    playVideo: (data) => dispatch(playVideo(data)),
    startClass: (data) => dispatch(startClass(data)),
    setExitRoom: (data) => dispatch(exitRoom(data)),
    enableSpeakAll: (data) => dispatch(enableSpeakAll(data)),
    muteAllStudents: (data) => dispatch(muteAllStudents(data)),
    speakToAllStatus: (data) => dispatch(speakToAllStatus(data)),
    muteUnmuteTeacherMic: (data) => dispatch(muteUnmuteTeacherMic(data)),
    muteUnmuteVideoPlayer: (data) => dispatch(muteUnmuteVideoPlayer(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    pending: state.pending,
    exitRoom: state.exitRoom,
    roomJoined: state.roomJoined,
    studentIds: state.studentIds,
    isSyncVideo: state.isSyncVideo,
    isPlayVideo: state.isPlayVideo,
    otsRoomData: state.otsRoomData,
    muteStudents: state.muteStudents,
    isVideoPLayed: state.isVideoPLayed,
    isTeacherMuted: state.isTeacherMuted,
    localParticipantSid: state.localParticipantSid,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StartClassFooter);
