import React, { useState } from "react";
import { Button } from "antd";

const EditDateTimeModal = (props) => {

    const { handleChangeDateModal } = props;

    const wrapperStyles = {
        display: 'flex',
        position: 'fixed', /* Stay in place */
        zIndex: 1, /* Sit on top */
        left: 0,
        top: 0,
        width: '100vw', /* Full width */
        height: '100vh', /* Full height */
        overflow: 'auto', /* Enable scroll if needed */
        backgroundColor: 'rgba(0,0,0,0.4)', /* Black w/ opacity */
    }
    const participantStyle = {
        position: "relative",
        width: window.innerWidth > 766 ? 608 : 344,
        height: window.innerWidth > 766 ? 350 : 440,
        margin: 'auto',
        borderRadius: 5,
        backgroundColor: "white"
    };
    
    return (
        <div onClick={() => {handleChangeDateModal()}} style={wrapperStyles} >
            <div className='modal' style={participantStyle} onClick={(e) => { e.stopPropagation(); }}>
                <span className='modal-heading' >
                    Propose new date and/or time
                </span>
                <span className='modal-description'>
                    We'll send a new date and/or time requeust to students. They may no longer be able to attend the
                    class and will have an option to cancel.
                </span>
                <span className='modal-description' style={{ justifyContent: 'unset' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span className='modal-description-heading'>
                            New date and time
                        </span>
                        <span className='modal-description-datetime'>
                            Today, 4 Jul at 9:00 am
                        </span>
                    </div>
                </span>
                <div className='modal-select'>
                    <Button className="modal-button" onClick={() => {handleChangeDateModal()}}>
                        Close
                    </Button>
                    <Button className="modal-button-cancel">Submit</Button>
                </div>
            </div>
        </div>
    );
}

export default EditDateTimeModal;