import React, { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import moment from 'moment';

import HighChartGraph from '../HighCharts/highChartGraph';
import { ReactComponent as UpArrow } from '../../assets/img/icons/arrow-triangle-up.svg';
import { ReactComponent as DownArrow } from '../../assets/img/icons/arrow-down-red.svg';

const { Panel } = Collapse;

const Layout = (props) => {
    const { item, graphData } = props;

    return (
        <div>
            <div className="perfomance-item" >
                <span className="perfomance-item-title">
                    {item.item.title}
                </span>
                {item.name === 'timeWatched' ? (
                    <>
                        {item.item.current === 0 ? (
                            <span className="perfomance-item-count">
                                0
                            </span>
                        ) : (
                            <span className="perfomance-item-count">
                                {Math.floor(item.item.current / 3600)}
                                <span style={{ fontSize: 16 }}>
                                    {` hrs `}
                                </span>
                                {Math.floor(item.item.current / 60) - (Math.floor(item.item.current / 3600) * 60)}
                                <span style={{ fontSize: 16 }}>
                                    {` mins`}
                                </span>
                            </span>
                        )}
                    </>
                ) : (
                    <span className="perfomance-item-count">
                        {item.item.current % 1 != 0 ? `$${item.item.current.toFixed(2)}` :
                            item.item.current}
                    </span>
                )}
            </div>
            <div className="perfomance-item">
                <span className="perfomance-item-graph">
                    <HighChartGraph
                        height={60}
                        width={100}
                        increaseYaxis={true}
                        gridLines={false}
                        pointPlacement={false}
                        data={graphData}
                        valuePrefixGraph={item.name === 'earnings' ? '$' : ''}
                        valueSuffixGraph={item.name === 'timeWatched' ? 'hrs' : ''}
                    />
                </span>
                <span className="perfomance-item-percentage">
                    {item.item.current === 0 && item.item.previous === 0 &&
                        !Object.keys(item.item.timeline).length ?
                        (
                            <span style={{ fontSize: 14, fontWeight: 600, color: '#929292' }}>
                                Waiting for data
                            </span>
                        ) : (
                            <>
                                {item.item.current > item.item.previous ? (
                                    <UpArrow className="uparrow-icon" />
                                ) : (
                                    <DownArrow className="down-arrow-icon" />
                                )}
                                <span style={{ fontSize: 14, fontWeight: 600, color: '#262626' }}>
                                    {item.item.current === 0 || item.item.previous === 0 ? `0% ` :
                                        `${getChange(item.item.current, item.item.previous).toFixed(0)}% `}
                                    from last week
                                </span>
                            </>
                        )}
                </span>
            </div>
        </div>
    );
}

const getChange = (current, previous) => {
    let change = current / previous || 0;
    if (current > previous) {
        change -= 1;
    } else {
        change = 1 - change;
    }

    return change * 100;
}

const AnalyticsBanner = (props) => {
    const { item } = props;

    let initialData = [];
    let firstColor = '';
    let xAxisGraphData = [];

    const [graphData, setGraphData] = useState({
        firstDataName: "",
        firstDataColor: firstColor,
        firstData: initialData,
        xAxisData: xAxisGraphData
    });

    useEffect(() => {
        Object.keys(item.item.timeline).map(function (key, index) {
            const timeline = item.item.timeline[key];
            if (item.name === 'earnings') {
                initialData.push(parseFloat(timeline.toFixed(2)));
            }
            else if (item.name === 'timeWatched') {
                initialData.push(parseFloat((timeline / 3600).toFixed(2)));
            }
            else {
                initialData.push(timeline);
            }
            xAxisGraphData.push(`${moment(key).format("DD")}/${moment(key).format("MMM")}`);
        });
        firstColor = '#1d615a';
        if (!initialData.length) {
            initialData = [1, 2, 5, 3, 4, 1, 6];
            firstColor = '#D4D4D4'
        }
        setGraphData(Object.assign({}, graphData, {
            firstData: initialData,
            firstDataColor: firstColor,
            xAxisData: xAxisGraphData
        }));
    }, []);

    return (
        <Collapse ghost style={{ boxShadow: '0 0 10px 1px #9999995e', borderRadius: 10 }}
            className='analytic-banner-collapse'
            collapsible={
                item.item.current === 0 && item.item.previous === 0
                    && !Object.keys(item.item.timeline).length ?
                    "disabled" : "header"}
        >
            <Panel showArrow={false} header={<Layout item={item} graphData={graphData} />}
                key={item.index} expandIcon="disable">
                <HighChartGraph
                    gridLines={false}
                    increaseYaxis={true}
                    data={graphData}
                    valuePrefixGraph={item.name === 'earnings' ? '$' : ''}
                    valueSuffixGraph={item.name === 'timeWatched' ? 'hrs' : ''}
                />
            </Panel>
        </Collapse>
    );
}

export default AnalyticsBanner;
