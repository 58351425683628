import * as Actions from "./actionTypes";

export const postLoginData = (payload) => ({
  type: Actions.POST_LOGINDATA_REQUEST,
  payload: payload,
});

export const createClass = (payload) => ({
  type: Actions.POST_CREATECLASS_REQUEST,
  payload: payload,
});

export const endClass = (payload) => ({
  type: Actions.POST_ENDCLASS_REQUEST,
  payload: payload,
});

export const playVideo = (payload) => ({
  type: Actions.GET_PLAYVIDEO_ACCESS,
  payload: payload,
});

export const changeLoginState = (payload) => ({
  type: Actions.CHANGE_LOGIN_STATE,
  payload: payload,
});

export const enableSpeakAll = (payload) => ({
  type: Actions.ENABLE_SPEAKALL_REQUEST,
  payload: payload,
});

export const removeParticipant = (payload) => ({
  type: Actions.REMOVE_PARTICIPANT,
  payload: payload,
});

export const addParticipant = (payload) => ({
  type: Actions.ADD_PARTICIPANT,
  payload: payload,
});

export const removeId = (payload) => ({
  type: Actions.REMOVE_ID,
  payload: payload,
});

export const addId = (payload) => ({
  type: Actions.ADD_ID,
  payload: payload,
});

export const storeRoomToken = (payload) => ({
  type: Actions.STORE_ROOM_TOKEN,
  payload: payload,
});

export const muteUnmuteVideoPlayer = (payload) => ({
  type: Actions.MUTE_UNMUTE_VIDEOPLAYER,
  payload: payload,
});

export const muteUnmuteTeacherMic = (payload) => ({
  type: Actions.MUTE_UNMUTE_TEACHERMIC,
  payload: payload,
});

export const videoPlayed = (payload) => ({
  type: Actions.VIDEO_PLAYED,
  payload: payload,
});

export const newParticipantConnected = (payload) => ({
  type: Actions.NEW_PARTICIPANT_CONNECTED,
  payload: payload,
});

export const totalParticipants = (payload) => ({
  type: Actions.TOTAL_PARTICIPANT,
  payload: payload,
});

export const startClass = (payload) => ({
  type: Actions.START_CLASS,
  payload: payload,
});

export const exitRoom = (payload) => ({
  type: Actions.EXIT_ROOM,
  payload: payload,
});

export const syncVideo = (payload) => ({
  type: Actions.SYNC_VIDEO,
  payload: payload,
});

export const changeStudentRules = (payload) => ({
  type: Actions.CHANGE_STUDENT_RULE_REQUEST,
  payload: payload,
});

export const muteAllStudents = (payload) => ({
  type: Actions.MUTE_ALL_STUDENTS,
  payload: payload,
});

export const roomJoined = (payload) => ({
  type: Actions.ROOM_JOINED,
  payload: payload,
});

export const loading = (payload) => ({
  type: Actions.LOADING,
  payload: payload,
});

export const addLocalParticipantId = (payload) => ({
  type: Actions.ADD_LOCAL_PARTICIPANT_ID,
  payload: payload,
});

export const storeRoomData = (payload) => ({
  type: Actions.STORE_ROOM_DATA,
  payload: payload,
});

export const updateRulesforNewParticipant = (payload) => ({
  type: Actions.UPDATE_RULES,
  payload: payload,
});

export const speakToAllStatus = (payload) => ({
  type: Actions.SPEAKTOALL_STATUS,
  payload: payload,
});

export const communicationWay = (payload) => ({
  type: Actions.COMMUNICATION_WAY,
  payload: payload,
});

export const redirectRoute = (payload) => ({
  type: Actions.REDIRECT_ROUTE,
  payload: payload,
});

export const getAllOtsClasses = (payload) => ({
  type: Actions.GET_ALL_OTS_CLASSES_REQUEST,
  payload: payload,
});

export const getOtsClass = (payload) => ({
  type: Actions.GET_OTS_CLASS_REQUEST,
  payload: payload,
});

export const getAllClasses = (payload) => ({
  type: Actions.GET_ALL_CLASSES_REQUEST,
  payload: payload,
});

export const createOTSCLass = (payload) => ({
  type: Actions.CREATE_OTS_REQUEST,
  payload: payload,
});

export const getOtsClassStatus = (payload) => ({
  type: Actions.OTS_CLASS_STATUS,
  payload: payload,
});

export const createOTSRoom = (payload) => ({
  type: Actions.CREATE_OTSROOM_REQUEST,
  payload: payload,
});

export const endOTSRoom = (payload) => ({
  type: Actions.END_OTSROOM_REQUEST,
  payload: payload,
});

export const storeLoggedInStatus = (payload) => ({
  type: Actions.KEEPME_LOGGEDIN,
  payload: payload,
});

export const logoutUser = (payload) => ({
  type: Actions.LOGOUT_USER,
  payload: payload,
});

export const fetchProfileDetails = (payload) => ({
  type: Actions.FETCH_TEACHER_PROFILE_DETAILS_REQUEST,
  payload: payload,
});

export const updateTeacherPassword = (payload) => ({
  type: Actions.UPDATE_TEACHER_PASSWORD_REQUEST,
  payload: payload,
});

export const teacherProfileBioUpdate = (payload) => ({
  type: Actions.TEACHER_PROFILE_BIO_UPDATE_REQUEST,
  payload: payload,
});

export const teacherProfileUpdate = (payload) => ({
  type: Actions.TEACHER_PROFILE_UPDATE_REQUEST,
  payload: payload,
});

export const teacherProfileImageUpdate = (payload, field = null, Imagee = null) => ({
  type: Actions.TEACHER_PROFILE_IMAGE_UPDATE_REQUEST,
  payload: { ...payload, field, Imagee },
});

export const audioTest = (payload) => ({
  type: Actions.AUDIO_TEST,
  payload: payload,
});

export const updateOTSCLass = (payload) => ({
  type: Actions.UPDATE_OTS_REQUEST,
  payload: payload,
});

export const zoomModal = (payload) => ({
  type: Actions.OPEN_ZOOM_MODAL,
  payload: payload,
});

export const getTeacherPerformance = (payload) => ({
  type: Actions.GET_TEACHER_PERFORMANCE_REQUEST,
  payload: payload,
});


export const getTeacherEarnings = (payload) => ({
  type: Actions.GET_TEACHER_EARNINGS_REQUEST,
  payload: payload,
});

export const getAllSimpleClasses = (payload) => ({
  type: Actions.GET_ALL_SIMPLE_CLASSES_REQUEST,
  payload: payload,
});

export const changeHeader = (payload) => ({
  type: Actions.CHANGE_HEADER,
  payload: payload,
});

export const cancelOTSClass = (payload) => ({
  type: Actions.CANCEL_OTS_CLASS_REQUEST,
  payload: payload,
});