import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import ReactPlayer from "react-player";
import { usePubNub } from "pubnub-react";

import JoinRoom from "../../components/JoinRoom";
import { playVideo, videoPlayed } from "../../actions/actions";
import StartClassFooter from "../../components/Footer/startClassFooter";

let playedSeconds = 0;

const Home = (props) => {
  const {
    isPlayerMuted,
    newParticipant,
    isSyncVideo,
    createdClass,
    isPlayVideo,
    muteStudents,
    speakToAllEnabled,
    twoWayCommunication,
    otsRoomData,
    tokenExpire
  } = props;

  const channelName = `OTS_TWL_${otsRoomData.OTS.roomID}`;
  let speakAll = useRef(twoWayCommunication);
  let mutedStudent = useRef(muteStudents);
  const userName = props.loginData ? props.loginData.userName : "";
  const pubnub = usePubNub();
  const [playerStatus, setPlayerStatus] = useState(200);

  useEffect(() => {
    pubnub.addListener({ message: handleMessage });
    pubnub.subscribe({ channelName });
  }, [pubnub]);

  useEffect(() => {
    if (tokenExpire) {
      props.history.push("/")
    }
  }, [tokenExpire]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  useEffect(() => {
    speakAll.current = twoWayCommunication;
  }, [twoWayCommunication]);

  useEffect(() => {
    mutedStudent.current = muteStudents;
    pubnub.publish({
      channel: channelName,
      message: {
        playerStatus: playerStatus,
        seconds: playedSeconds,
      },
    });
  }, [playerStatus, muteStudents]);

  let participantCount = React.useRef(0);
  useEffect(() => {
    const seconds = playedSeconds + 1;
    if (newParticipant >= participantCount.current) {
      participantCount.current = newParticipant;
      pubnub.publish({
        channel: channelName,
        message: {
          playerStatus: playerStatus,
          seconds: seconds,
          muteStatus: muteStudents ? 501 : 500,
          statusCode: speakToAllEnabled ? 101 : muteStudents ? 103 : null,
        },
      });
    } else if (newParticipant < participantCount.current) {
      participantCount.current = newParticipant;
    }
  }, [newParticipant]);

  useEffect(() => {
    pubnub.publish({
      channel: channelName,
      message: {
        playerStatus: playerStatus,
        seconds: playedSeconds,
        muteStatus: muteStudents ? 501 : 500,
      },
    });
  }, [isSyncVideo]);

  useEffect(() => {
    const seconds = playedSeconds + 1;
    pubnub.publish({
      channel: channelName,
      message: {
        playerStatus: playerStatus,
        seconds: seconds,
        muteStatus: muteStudents ? 501 : 500,
      },
    });
  }, [props.isVideoPLayed]);

  const handleMessage = (event) => {
    if (event.message.statusCode === "402") {
      let studentId = event.message.sid;
      setPlayerStatus((playerStatusCurrent) => {
        const seconds = playedSeconds + 1;
        pubnub.publish({
          channel: channelName,
          message: {
            playerStatus: playerStatusCurrent,
            seconds: seconds,
            muteStatus: mutedStudent.current ? 501 : 500,
            statusCode: speakAll.current ? 1012 : 1011,
            studentId: studentId,
          },
        });
        return playerStatusCurrent;
      });
    }
  };

  const onPause = () => {
    setPlayerStatus(200);
    props.playVideo(false);
  };

  const onPlay = () => {
    setPlayerStatus(201);
    props.playVideo(true);
  };

  const onSeek = (seconds) => {
    playedSeconds = seconds;
    pubnub.publish({
      channel: channelName,
      message: {
        playerStatus: playerStatus,
        seconds: playedSeconds,
        muteStatus: muteStudents ? 501 : 500,
      },
    });
  };

  const onProgress = (seconds) => {
    playedSeconds = seconds.playedSeconds;
  };

  const playerStyle2 = {
    display: "flex",
    float: "left",
    position: "relative",
    top: newParticipant > 15 ? "50px" : "100px",
  };

  const headingStyle = {
    position: "relative",
    top: newParticipant > 15 ? "30px" : "60px",
  };

  const playerStyle = {
    float: "left",
    display: "flex",
    height: newParticipant > 15 ? null : "57vh",
  };

  return (
    <>
      <div className="bg-home" style={{ paddingLeft: 10, 
        //minHeight: window.innerHeight - 60 
        }}>
        <div className="row">
          <div>
            <div className="row" style={headingStyle}>
              <h2
                style={{
                  fontWeight: 700,
                  paddingLeft: 5,
                }}
              >
                {otsRoomData.OTS.name}
              </h2>
              <h4
                style={{
                  fontWeight: 500,
                  paddingLeft: 5,
                }}
              >
                Particiants: {newParticipant ? newParticipant : 0}
              </h4>
            </div>
            <div className="row">
              <div className="player" style={playerStyle}>
                <ReactPlayer
                  controls={true}
                  muted={isPlayerMuted}
                  playing={isPlayVideo}
                  onPause={onPause}
                  onPlay={onPlay}
                  onSeek={onSeek}
                  onProgress={onProgress}
                  url="https://stream.mux.com/g601beS02u5ZldNspKRWaiLyWnyfLNlhnpuxTe9IlfFw8.m3u8?token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJ2IiwiZXhwIjoxNjMyNzE1MzMxLCJraWQiOiJJeUgya2tNc0JSdmcyZWN0RUR5ZTAxb0FiUFBDenYzZnhiMDIwMFhVamdGWFpVIiwic3ViIjoiZzYwMWJlUzAydTVabGROc3BLUldhaUx5V255ZkxObGhucHV4VGU5SWxmRnc4In0.fY6RTwXdY3FIHKLSYYOogpz2kEfvA4Pz3DpLjTc5fbzl5vgfmO0n_wOK239ECSdKGbF-Uzw1Hz4tv4F3KaE2ho418kG_DGWw85lIAbe4Qv6uIItTzzW4q98lNHL4P2dn__5omRQVkKzo62XD2KXhVXDUPiyEbt_ybdNoK46aZoSXHtieFKnG1EBeEp8bLj9zZSwEO7wpAJM45p5N2PFEpgs4ZI8jtgfDX59igDm0MzV-L22FE-uHxKb17ePvz2KX0wuYAmY5-A93y1qwxAM_gGUVUS2lYbWAI9nQ5GZNvBN5LBDy39tAH_fNyVhMerEQtYCL9ieHLgxyTmrNCCQvBw"
                  style={playerStyle2}
                  className="video-player"
                />
              </div>
            </div>
            <div className="student-videos" style={{ textAlign: "center" }}>
              <JoinRoom
                userName={userName} />
            </div>
            <div className="class-footer">
              <StartClassFooter />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    videoPlayed: (data) => dispatch(videoPlayed(data)),
    playVideo: (data) => dispatch(playVideo(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    loginData: state.loginData,
    isSyncVideo: state.isSyncVideo,
    isPlayVideo: state.isPlayVideo,
    muteStudents: state.muteStudents,
    otsRoomData: state.otsRoomData,
    tokenExpire: state.tokenExpire,
    createdClass: state.createdClass,
    isVideoPLayed: state.isVideoPLayed,
    isPlayerMuted: state.isPlayerMuted,
    newParticipant: state.newParticipant,
    isTeacherMuted: state.isTeacherMuted,
    speakToAllEnabled: state.speakToAllEnabled,
    twoWayCommunication: state.twoWayCommunication,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
