import React from "react";
import { Spring } from "react-spring";

const VerticalProgress = ({ progress, isLastBar, verticalBar, horizontalBar }) => {

  return (
    <Spring from={{ percent: 0 }} to={{ percent: progress }}>
      {({ percent }) => (
        <div className="progress vertical" style={{
          height: horizontalBar ? verticalBar ? 25 : `${progress}%` : null,
          width: horizontalBar ? verticalBar ? `${progress}%` : 30 : null,
          alignSelf: "center",
          marginBottom: 0
        }}>
          <div style={{
            height: !horizontalBar ? verticalBar ? `${progress}%` : 30 : null,
            backgroundColor: isLastBar
          }}
            className="progress-bar" >
          </div>
        </div>
      )}
    </Spring>
  );
};

export default VerticalProgress;