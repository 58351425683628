import React from "react";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const HighChartGraph = (props) => {

    const {
        data, width, height, gridLines, linesWidth,
        pointPlacement, removeYaxis, enableFirstAndLastXaxisLables,
        increaseYaxis,
        valuePrefixGraph,
        valueSuffixGraph
    } = props;

    const options = {
        chart: {
            width: width ?? null,
            height: height ?? 250
        },
        title: {
            text: ''
        },
        tooltip: {
            enabled: data.firstDataColor !== '#D4D4D4' ? true : false,
            valuePrefix: valuePrefixGraph,
            valueSuffix: valueSuffixGraph
        },
        series: [{
            type: 'spline',
            showInLegend: false,
            data: data.firstData,
            name: data.firstDataName,
            color: data.firstDataColor,
            marker: {
                enabled: false
            },
        },
        {
            type: 'spline',
            showInLegend: false,
            data: data.secondData,
            name: data.secondDataName,
            color: data.secondDataColor,
            marker: {
                enabled: false
            },
        },
        {
            type: 'spline',
            showInLegend: false,
            data: data.thirdData,
            name: data.thirdDataName,
            color: data.thirdDataColor,
            marker: {
                enabled: false
            },
        }
        ],
        credits: {
            enabled: false
        },
        xAxis: {
            categories: data.xAxisData,
            tickmarkPlacement: 'on',
            labels: {
                style: {
                    color: '#262626',
                    font: '16px "Gilroy", sans-serif',
                    fontWeight: 600
                },
                enabled: gridLines ?? true,
                formatter: enableFirstAndLastXaxisLables ? function () {
                    if (this.isFirst || this.isLast) {
                        return this.value
                    } else {
                        return ''
                    }
                } : null
            },

            lineWidth: linesWidth ?? 0,

        },
        plotOptions: {
            lineWidth: 1,

            series: {
                pointPlacement: pointPlacement ? null : 'on'
            }
        },
        yAxis: {
            min: increaseYaxis ? -1 : 0,
            visible: removeYaxis ? false : true,
            title: {
                enabled: false,
            },
            labels: {
                style: {
                    color: '#262626',
                    font: '16px "Gilroy", sans-serif',
                    fontWeight: 600
                },
                enabled: gridLines ?? true,
            },
            gridLineWidth: linesWidth ?? 0,
        },
    }

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )

}

export default HighChartGraph;