import React, { useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { Tabs } from 'antd';

import Title from '../../components/Title/Title';
import { getTeacherEarnings } from '../../actions/actions';
import Statements from '../../components/Earnings/statements';
import TotalEarnings from '../../components/Earnings/totalEarnings';
import EarningsGraph from '../../components/Earnings/earningsGraph';
import LoadingAnimation from '../../components/Loader/LoadingAnimation';
import TotalReferrals from '../../components/Earnings/totalReferrals';

const { TabPane } = Tabs;

const Earning = (props) => {

    const { loginData, teacherEarningsData } = props;
    useEffect(() => {
        const data = {
            token: loginData ? loginData.access : null,
            teacherId: loginData ? loginData.userID : null,
        };

        !teacherEarningsData.totalEarnings && props.getTeacherEarnings(data);
    }, []);

    const data = [
        {
            date: '7 Jun - 13 Jun 2021',
            amount: 2532
        },
        {
            date: '17 Jun - 23 Jun 2021',
            amount: 3461
        },
        {
            date: '4 Jun - 19 Jun 2021',
            amount: 2874
        }
    ]

    return (
        <div
            className='main-page-container'
        >
            <Title text={'Earnings'} />
            {teacherEarningsData.totalEarnings ?
                <div className="earnings-tab">
                    <Tabs defaultActiveKey="1" >
                        <TabPane tab="EARNINGS" key="1">
                            <div className="earnings-main">
                                <TotalEarnings totalEarnings={teacherEarningsData.totalEarnings} name={true} value={"week"} />
                                <div className="line-divider" ></div>
                                <div>
                                    <TotalReferrals totalEarnings={teacherEarningsData.totalEarnings}/>
                                </div>
                                <div className="line-divider" ></div>
                                <EarningsGraph
                                    earningsByTime={teacherEarningsData.earningsByTime}
                                    totalEarnings={teacherEarningsData.totalEarnings.total}
                                />
                            </div>
                        </TabPane>
                        <TabPane tab="STATEMENTS" key="2" disabled>
                            <div className='statements-main'>
                                {data.map((item, index) => (
                                    <Statements
                                        data={item}
                                    />
                                ))
                                }
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
                :
                <LoadingAnimation />
            }
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        getTeacherEarnings: (data) => dispatch(getTeacherEarnings(data)),
    };
};

const mapStateToProps = (state) => {
    return {
        loginData: state.loginData,
        teacherEarningsData: state.teacherEarningsData
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Earning);