import React from "react";

const LoadingAnimation = () => {

    return (
        <div className="wrap-loading">
            <div className="lds-ellipsis">
                <div />
                <div />
                <div />
                <div />
            </div>
        </div>
    )

}

export default LoadingAnimation;