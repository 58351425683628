import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import Title from '../../components/Title/Title';
import { getTeacherPerformance } from '../../actions/actions';
import ViewsGraph from '../../components/HighCharts/viewsGraph';
import AudienceCard from '../../components/Audience/audienceCard';
import LoadingAnimation from '../../components/Loader/LoadingAnimation';
import YourTopThreeCard from '../../components/YourTop3/YourTopThreeCard';
import AnalyticsBanner from '../../components/AnalyticsBanner/AnalyticsBanner';
import YourTopThreeHeading from '../../components/YourTop3/YourTopThreeHeading';


const Performance = (props) => {

    const { loginData, teacherPerformanceData } = props;

    const [performanceArray, setPerformanceArray] = useState([]);
    const [teacherAudience, setTeacherAudience] = useState([]);

    useEffect(() => {
        if (teacherPerformanceData.views) {
            setPerformanceArray(Object.values(teacherPerformanceData));
            Object.keys(teacherPerformanceData.teacherAudienceInfo).map(function (key) {
                setTeacherAudience(prevItems => [...prevItems, {
                    name: key,
                    detail: teacherPerformanceData.teacherAudienceInfo[key]
                }]);
            });
        }
    }, [teacherPerformanceData]);

    useEffect(() => {
        const data = {
            token: loginData ? loginData.access : null,
            teacherId: loginData ? loginData.userID : null,
        };
        !teacherPerformanceData.views && props.getTeacherPerformance(data);
    }, []);

    return (
        <div
            className='main-page-container'
        >

            <Title text={'Performance'} />
            {teacherPerformanceData.views ?
                <>
                    <div className={'analytic-banner flex-wrapper'} style={{ borderRadius: 10 }}>

                        {Object.values(teacherPerformanceData.overview).map((item, index) => (
                            <>
                                <div className={'flex-wrapper-item-50'} >
                                    <AnalyticsBanner item={Object.assign({
                                        name: Object.keys(teacherPerformanceData.overview)[index]
                                    },
                                        { item }, { index })}
                                    />
                                </div>
                            </>
                        ))}
                    </div>



                    <YourTopThreeHeading />
                    {performanceArray[2] &&
                        performanceArray[2].highestEarning.class ?
                        (
                            <div className="top-performer your-top-three-main">
                                {performanceArray[2] &&
                                    Object.values(performanceArray[2]).map((card, index) => (
                                        <div className="your-top-three-card" >
                                            <YourTopThreeCard
                                                card={Object.assign({
                                                    name: Object.keys(performanceArray[2])[index]
                                                },
                                                    { card })}
                                            />
                                        </div>
                                    ))}
                            </div>
                        ) : (
                            <span class='perfomance-item-title'
                                style={{ justifyContent: 'center', color: '#929292' }}>
                                We'll show your best performing classes here.
                            </span>
                        )

                    }

                    <div className="your-top-three" style={{ marginTop: 72, marginBottom: 48 }}>
                        <span className="your-top-three-title">Views</span>
                    </div>

                    <ViewsGraph />

                    <div className="your-top-three" style={{ marginTop: 72, marginBottom: 64 }}>
                        <span className="your-top-three-title">Know your audience</span>
                    </div>
                    <div className="audience-main audience-section">
                        {teacherAudience[1] && teacherAudience.map((item, index) => (
                            <div className="audience-map" >
                                <AudienceCard item={item} index={index} />
                            </div>
                        ))}
                    </div>
                </>
                :
                <LoadingAnimation />
            }
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        getTeacherPerformance: (data) => dispatch(getTeacherPerformance(data)),
    };
};

const mapStateToProps = (state) => {
    return {
        loginData: state.loginData,
        teacherPerformanceData: state.teacherPerformanceData
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Performance);

