
import React, { useState, useLayoutEffect, useEffect } from "react";
import { Button, Divider } from "antd";
import moment from 'moment';

import CancelClassModal from "../cancelClassModal";
import EditDateTimeModal from "./editDateTimeModal";
import EditCostModal from "./editCostModal";
import AddSpotModal from "./addSpotModal";
import { ReactComponent as CostIcon } from "../../../assets/img/icons/cost.svg";
import { ReactComponent as TotalDuration } from "../../../assets/img/icons/time_watched.svg";
import { ReactComponent as TotalEarnings } from "../../../assets/img/icons/total_earnings.svg";
import { ReactComponent as Participants } from "../../../assets/img/icons/participants_new.svg";


function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeigh]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

const EditStatsTitle = (props) => {
    const { selectedClass, handleClassChange } = props;
    const [width, height] = useWindowSize();

    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [openDateModal, setOpenDateModal] = useState(false);
    const [openCostModal, setOpenCostModal] = useState(false);
    const [openParticipantsModal, setOpenParticipantsModal] = useState(false);

    const handleChangeCancelModal = () => {
        setOpenCancelModal(!openCancelModal);
    };

    const handleChangeDateModal = () => {
        setOpenDateModal(!openDateModal);
    };

    const handleChangeCostModal = () => {
        setOpenCostModal(!openCostModal);
    };

    const handleChangeParticipantsModal = () => {
        setOpenParticipantsModal(!openParticipantsModal);
    };


    return (
        <div className="edit-stats">
            <div className="edit-stats-heading">
                Direct Coach
            </div>
            <div className='edit-ots-main'>
                <div className='edit-ots'>
                    <div className='edit-ots-icon'>
                        <TotalEarnings />
                    </div>
                    <span className='edit-ots-icon-heading'>
                        Est. earnings
                    </span>
                    <span className='edit-ots-icon-value'>
                        ${(selectedClass.classData.OTS.maxParticipants * selectedClass.classData.OTS.price).toFixed(2)}
                    </span>
                </div>
                {width < 768 &&
                    <Divider className='edit-ots-divider' />
                }

                <div className='edit-ots'>
                    <div className='edit-ots-icon'>
                        <Participants />
                    </div>
                    <span className='edit-ots-icon-heading'>
                        Participants
                    </span>
                    <span className='edit-ots-icon-value'>
                    {selectedClass.classData.Booked}/{selectedClass.classData.OTS.maxParticipants}
                    </span>
                </div>
                {width < 768 &&
                    <Divider className='edit-ots-divider' />
                }
                
                <div className='edit-ots'>
                    <div className='edit-ots-icon'>
                        <CostIcon />
                    </div>
                    <span className='edit-ots-icon-heading'>
                        Cost
                    </span>
                    <span className='edit-ots-icon-value'>
                        ${selectedClass.classData.OTS.price}
                    </span>
                </div>
                {width < 768 &&
                    <Divider className='edit-ots-divider' />
                }

                <div className='edit-ots'>
                    <div className='edit-ots-icon'>
                        <TotalDuration />
                    </div>
                    <span className='edit-ots-icon-heading'>
                        Duration
                    </span>
                    <span className='edit-ots-icon-value'>
                    {`${parseInt((selectedClass.classData.OTS.videoDuration ?? 0) / 60)} min`}
                    </span>
                </div>

            </div>
            <Divider style={{ margin: width < 768 ? '10px 0px' : '24px 0px'}} />
            <div className='edit-detail'>
                <div className='edit-detail-container'>
                    <span className='edit-detail-heading'>
                        Settings
                    </span>
                    <div className='edit-detail-timing'>
                        <div className='edit-detail-timeline'>
                            <span className='edit-detail-date'>
                                Date & time
                            </span>
                            <span className='edit-detail-timebutton' onClick={()=>{handleChangeDateModal()}} >
                                Edit
                            </span>
                        </div>
                        <span className='edit-detail-time'>
                            {moment(selectedClass.classData.OTS.startDateTime).format('dddd, DD MMM hh : mm a ')}
                            {moment(selectedClass.classData.OTS.endDateTime).format('- hh : mm a')}
                        </span>
                    </div>
                    <Divider style={{ margin: width < 768 ? '12px 0px' : '24px 0px'}}  />
                    <div className='edit-detail-timing'>
                        <div className='edit-detail-timeline'>
                            <span className='edit-detail-date'>
                                Students
                            </span>
                            <span className='edit-detail-timebutton' onClick={()=>{handleChangeParticipantsModal()}}>
                                Edit
                            </span>
                        </div>
                        <span className='edit-detail-time'>
                            {selectedClass.classData.Booked}/{selectedClass.classData.OTS.maxParticipants}
                        </span>
                    </div>
                    <Divider style={{ margin: width < 768 ? '12px 0px' : '24px 0px'}}  />
                    <div className='edit-detail-timing'>
                        <div className='edit-detail-timeline'>
                            <span className='edit-detail-date'>
                                Cost
                            </span>
                            <span className='edit-detail-timebutton' onClick={()=>{handleChangeCostModal()}}>
                                Edit
                            </span>
                        </div>
                        <span className='edit-detail-time'>
                            ${selectedClass.classData.OTS.price}
                        </span>
                    </div>
                    <div className='edit-detail-earnings'>
                            <span className='edit-detail-earnings-heading'>
                                Estimated earnings
                            </span>
                            <div className='edit-detail-earnings-container' style={{ paddingTop: 20 }}>
                                <span className='edit-detail-earnings-name'>
                                    Students
                                </span>
                                <span className='edit-detail-earnings-name'>
                                    {selectedClass.classData.OTS.maxParticipants}
                                </span>
                            </div>
                            <div className='edit-detail-earnings-container'>
                                <span className='edit-detail-earnings-name'>
                                    Cost
                                </span>
                                <span className='edit-detail-earnings-name'>
                                ${(selectedClass.classData.OTS.maxParticipants * selectedClass.classData.OTS.price).toFixed(2)}
                                </span>
                            </div>
                            <div className='edit-detail-earnings-container'>
                                <span className='edit-detail-earnings-name'>
                                    Platform Fee -20%
                                </span>
                                <span className='edit-detail-earnings-name'>
                                ${([(selectedClass.classData.OTS.maxParticipants * selectedClass.classData.OTS.price) / 100] * 20).toFixed(2)}
                                </span>
                            </div>
                            <div className='edit-detail-earnings-container'>
                                <span className='edit-detail-earnings-name' style={{ fontWeight: 600}}>
                                    Total
                                </span>
                                <span className='edit-detail-earnings-name' style={{ fontWeight: 600}}>
                                ${
                                (selectedClass.classData.OTS.maxParticipants * selectedClass.classData.OTS.price).toFixed(2) - 
                                ([(selectedClass.classData.OTS.maxParticipants * selectedClass.classData.OTS.price) / 100] * 20).toFixed(2)
                                }
                                </span>
                            </div>
                    </div>
                </div>
                {width < 768 &&
                    <Divider />
                }
                <div className='edit-detail-message'>
                    {/* <span className='edit-detail-heading'>
                        Message students
                    </span> */}
                    <div className='edit-detail-buttons'>
                        <div className='edit-detail-button-box' >
                        <Button className='edit-detail-support-button'>
                            Contact support
                        </Button>
                        </div>
                        <div className='edit-detail-button-box' onClick={()=>{ handleChangeCancelModal()}} >

                        <Button className='edit-detail-support-button'>
                            Cancel class
                        </Button>
                        </div>
                    </div>

                </div>
            </div>
            { openCancelModal && (
                <CancelClassModal 
                handleClassChange={handleClassChange} 
                selectedClass={selectedClass}
                handleChangeCancelModal={handleChangeCancelModal}
                />
                )
            }

            { openCostModal && (
                <EditCostModal handleChangeCostModal={handleChangeCostModal} />
                )
            }

            { openParticipantsModal && (
                <AddSpotModal handleChangeParticipantsModal={handleChangeParticipantsModal} />
                )
            }

            { openDateModal && (
                <EditDateTimeModal handleChangeDateModal={handleChangeDateModal} />
                )
            }
        </div>
    );
}

export default EditStatsTitle;