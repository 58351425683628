import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Button } from "antd";
import { ReactMic } from 'react-mic';

import TestAudio from "../../assets/audio/demoAudio.mp3"

const TestAudioVolume = () => {
    const [onRecording, setOnRecording] = useState(false);
    const [isVisualozationInitialized, setIsVisualozationInitialized] = useState(false);

    const [isVisualozationPaused, setIsVisualozationPaused] = useState(true);
    const audioRefNew = useRef();
    const canvasRefNew = useRef();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const createVisualization = () => {
        let ctx = new (window.AudioContext || window.webkitAudioContext)();
        if (!isVisualozationInitialized) {
            let audioRef = audioRefNew.current;
            let canvasRef = canvasRefNew.current;
            let analyser = ctx.createAnalyser();
            let audioSrc = ctx.createMediaElementSource(audioRef);
            audioSrc.connect(analyser);
            analyser.connect(ctx.destination);
            let cwidth = canvasRef.width;
            let cheight = canvasRef.height - 0;
            let meterWidth = 10; //width of the meters in the spectrum
            let gap = 2; //gap between meters
            let capHeight = 2;
            let capStyle = '#fff';
            let meterNum = 800 / (10 + 2); //count of the meters
            let capYPositionArray = []; ////store the vertical position of hte caps for the preivous frame
            ctx = canvasRef.getContext('2d');
            let gradient = ctx.createLinearGradient(0, 0, 0, 300);
            gradient.addColorStop(1, '#8fb594');
            gradient.addColorStop(0.5, '#8fb594');
            gradient.addColorStop(0, '#8fb594');
            // loop
            function renderFrame() {
                let array = new Uint8Array(analyser.frequencyBinCount);
                analyser.getByteFrequencyData(array);
                let step = Math.round(array.length / meterNum); //sample limited data from the total array
                ctx.clearRect(0, 0, cwidth, cheight);
                for (let i = 0; i < meterNum; i++) {
                    let value = array[i * step];
                    if (capYPositionArray.length < Math.round(meterNum)) {
                        capYPositionArray.push(value);
                    };
                    ctx.fillStyle = capStyle;
                    //draw the cap, with transition effect
                    if (value < capYPositionArray[i]) {
                        ctx.fillRect(i * 12, cheight - (--capYPositionArray[i]), meterWidth, capHeight);
                    } else {
                        ctx.fillRect(i * 12, cheight - value, meterWidth, capHeight);
                        capYPositionArray[i] = value;
                    };
                    ctx.fillStyle = gradient; //set the filllStyle to gradient for a better look
                    ctx.fillRect(i * 11 /*meterWidth+gap*/, cheight - value + capHeight, meterWidth, cheight); //the meter
                }
                requestAnimationFrame(renderFrame);
            }
            renderFrame();
            audioRef.muted = false;
            audioRef.play();
            setIsVisualozationPaused(false);
            setIsVisualozationInitialized(true);
        } else if (isVisualozationPaused) {
            let audioRef = audioRefNew.current;
            audioRef.play();
            setIsVisualozationPaused(false)
        } else {
            ctx.suspend();
            audioRefNew.current.pause();
            setIsVisualozationPaused(true);
        }
    };

    const onAudioEnded = () => {
        setIsVisualozationPaused(true);
    }

    return (
        <div>
            <Row style={{ marginBottom: 30 }}>
                <Col
                    xs={24}
                    md={7}
                    lg={7}
                    className="volume-heading"
                >
                    <span>
                        Test Microphone
                    </span>
                </Col>
                <Col
                    xs={24}
                    md={17}
                    lg={17}
                    style={{ marginTop: 10 }}
                >
                    <div>
                        <ReactMic
                            record={onRecording}
                            className="sound-wave"
                            visualSetting="frequencyBars"
                            strokeColor="#8fb594"
                            backgroundColor="white"
                            width="475"
                            style={{ margin: "auto", display: "flex" }}
                        />
                    </div>
                    <div
                        className="footer-button"
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center"
                        }}
                    >
                        <Button
                            style={{
                                backgroundColor: "#8FB594",
                                borderColor: "#8FB594",
                                minWidth: 100,
                                padding: 20,
                                color: "#383838",
                                boxShadow: "none"
                            }}
                            type="primary"
                            htmlType="submit"
                            className="testscreen-button"
                            onClick={() => {
                                setOnRecording(!onRecording);
                            }}                >
                            {onRecording ? "Pause" : "Play"}
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row style={{ marginBottom: 30 }}>
                <Col
                    xs={24}
                    md={7}
                    lg={7}
                    className="volume-heading"
                >
                    <span>
                        Test Speaker
                    </span>
                </Col>
                <Col
                    xs={24}
                    md={17}
                    lg={17}
                    style={{ marginTop: 10 }}
                >
                    <div id="mp3_player">
                        <canvas
                            ref={canvasRefNew}
                            width="473" height="150"
                            className="speaker-canvas"
                        >

                        </canvas>
                        <br />
                        <br />
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            <audio
                                style={{ width: "100%" }}
                                src={TestAudio}
                                ref={audioRefNew}
                                onEnded={onAudioEnded}
                                muted="muted"
                            ></audio>
                        </div>
                        <div
                            className="footer-button"
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            <Button
                                style={{
                                    backgroundColor: "#8FB594",
                                    borderColor: "#8FB594",
                                    minWidth: 100,
                                    padding: 20,
                                    color: "#383838",
                                    boxShadow: "none"
                                }}
                                type="primary"
                                htmlType="submit"
                                className="testscreen-button"
                                onClick={() => {
                                    createVisualization();
                                }}                >
                                {!isVisualozationPaused ? "Pause" : "Play"}
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default withRouter(TestAudioVolume);
