import React from 'react';

const YourTopThreeHeading = () => {
    return ( 
        <div className="your-top-three" >
            <span className="your-top-three-title">Top Performers</span>
        </div>
     );
}
 
export default YourTopThreeHeading;