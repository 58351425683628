import React from "react";

const StatusBar = (props) => {

    const { status, calendar, text } = props;
    return (
        <>
            {calendar ?
                <div className="status-bar-calendar-main" >
                    <span className="status-bar-calendar-main-span" style={{
                        backgroundColor: (status === "Publishing" || status === "Published") ?
                            '#2e7d32' : (status === "pending" || status === "In review") ?
                                '#f57f17' : status === "Video Linked" ? "#d32f2f" : null
                    }}>{status}</span>
                </div>
                :
                <div className="status-bar-main" style={{
                    backgroundColor: status === 'planned' ?
                        '#f57f17' : status === 'published' ?
                            '#2e7d32' : status === 'expired' ?
                                '#d32f2f' : status === 'Publishing' ?
                                    '#388e3c' : 'red'
                }}>
                    <span>{text}</span>
                </div>
            }
        </>
    );
}

export default StatusBar;