import React, { useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Col, Row, Button } from "antd";
import { Link, withRouter } from "react-router-dom";

import HomeCarousel from "../../components/Carousel/carousel";
import OTSClassDetails from "../../components/OTSClasses/otsClassDetails";
import { getAllClasses, getAllOtsClasses, getOtsClassStatus } from "../../actions/actions";
import LoadingAnimation from "../../components/Loader/LoadingAnimation";


const OTSClasses = (props) => {

  const { otsRoomStatus, tokenExpire, loginData } = props;

  useEffect(() => {
    props.getOtsClassStatus(false);
    const data = {
      token: loginData ? loginData.access : null,
      teacherId: loginData ? loginData.userID : null,
    };
    props.getAllOtsClasses(data);
    props.getAllClasses(data);
  }, []);

  useEffect(() => {
    if (otsRoomStatus) {
      props.history.push("/home");
    }
  }, [otsRoomStatus]);

  useEffect(() => {
    if (tokenExpire) {
      props.history.push("/")
    }
  }, [tokenExpire]);

  return (
    <>
      {
        props.showLoader
          ?
          <LoadingAnimation />
          :
          <div style={{ height: 'min-height: calc(100vh - 110px)' }}>
            <HomeCarousel />
            <div className="ots-home" >
              <div style={{ height: "100px" }}>
                <Row style={{ justifyContent: "space-between" }}>
                  <Col>
                    <p className="ots-heading" style={{ marginTop: 10 }}>
                      OTS Classes
                    </p>
                  </Col>
                  <Col>
                    <div
                      className="footer-button"
                      style={{ width: "100%", paddingTop: 5 }}
                    >
                      <Link to="/otsClass/new">
                        <Button
                          style={{
                            backgroundColor: "#d5d5d5",
                            borderColor: "#d5d5d5",
                            width: 150,
                            height: 40,
                            fontWeight: 600,
                            textShadow: "none",
                            color: "black",
                            boxShadow: "none"
                          }}
                          type="primary"
                          htmlType="submit"
                          className="craete-ots-button"
                        >
                          Create OTS Class
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
              {

                props.otsClasses.length > 0
                  ?
                  <OTSClassDetails />
                  :
                  <div style={{ paddingTop: "5%" }}>
                    No classes found
                  </div>
              }
            </div>
          </div>
      }
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllClasses: (data) => dispatch(getAllClasses(data)),
    getAllOtsClasses: (data) => dispatch(getAllOtsClasses(data)),
    getOtsClassStatus: (data) => dispatch(getOtsClassStatus(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    loginData: state.loginData,
    otsClasses: state.otsClasses,
    showLoader: state.showLoader,
    tokenExpire: state.tokenExpire,
    otsRoomStatus: state.otsRoomStatus,
  };
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OTSClasses);
