import React from "react";

const NothingScreen = () => {
    return (  
        <div className="nothing-screen">
            <div className="nothing-screen-heading">
                Nothing here yet
            </div>
            <div className="nothing-screen-description">
            Direct Coach any of your existing classes and provide live feedback to students at an additional cost. <span className="nothing-screen-description-span">Learn more</span>
            </div>
            <div className="nothing-screen-button">
                <span className="nothing-screen-button-span">Coach a class</span>
            </div>
        </div>
     );
}
 
export default NothingScreen;