import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { Button } from "antd";

import ZoomDialog from './ZoomDialog';
import { zoomModal } from '../../actions/actions';
import CrossIcon from "../../assets/img/icons/cross.png";
import ZoomIcon from "../../assets/img/icons/expandIcon.svg";
import MicOnIcon from "../../assets/img/icons/micOnIcon.svg";
import MicOffIcon from "../../assets/img/icons/micOffIcon.svg";


const StudentInfoDialog = (props) => {
  let { closeDetailModal, doubleClick, isDoubleClicked, videoRef, audioRef, videoTracks, identity } = props;

  const videoReff = useRef();

  const [isZoomModalNew, setIsZoomModalNew] = useState(false);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoReff.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks, isZoomModalNew]);

  const openZoom = () => {
    setIsZoomModalNew(true);
  };

  const closeZoom = () => {
    setIsZoomModalNew(false);
  }

  const wrapperStyles = {
    display: 'flex',
    position: 'fixed', /* Stay in place */
    zIndex: 1, /* Sit on top */
    left: 0,
    top: 0,
    width: '100vw', /* Full width */
    height: '100vh', /* Full height */
    overflow: 'auto', /* Enable scroll if needed */
    backgroundColor: 'rgba(0,0,0,0.4)', /* Black w/ opacity */
  }
  const participantStyle = {
    position: "relative",
    width: 347,
    height: 196,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 10
  };

  return (
    <>
      {isZoomModalNew ? (
        <ZoomDialog
          closeDetailModal={closeDetailModal}
          closeZoom={closeZoom}
          identity={identity}
          videoTracks={videoTracks}
          audioRef={audioRef}
          videoRef={videoRef}
        />
      ) : (

        <div
          onClick={closeDetailModal}
          style={wrapperStyles}>
          <div className="student-info"
            onClick={(e) => { e.stopPropagation() }}
            style={{ margin: "auto", position: "relative", width: 377, backgroundColor: "white", borderRadius: 5 }}>
            <div
              onClick={(e) => { e.stopPropagation(); closeDetailModal(); }}
              className="video-info" style={participantStyle}>
              <div className="cross-icon-modal"
              >
                <img
                  style={{ width: "100%", cursor: "pointer" }} alt="" src={CrossIcon} />
              </div>
              <video style={{ boxShadow: "none", borderRadius: 5 }} ref={videoReff} autoPlay={true} />
              <audio ref={audioRef} autoPlay={true} muted={false} />
            </div>
            <div className="student">
              <div className="student-detail">
                <span>Skill Level</span>
                <span className="student-detail-description">Intermediate</span>
              </div>
              <div className="student-detail">
                <span>Location</span>
                <span className="student-detail-description">Sydney, Australia</span>
              </div>
              <div className="student-detail">
                <span>Recent Activity </span>
                <span className="student-detail-description">2 days ago</span>
              </div>
              <div className="student-detail">
                <span>Total Classes Attend</span>
                <span className="student-detail-description">10</span>
              </div>
              <div className="student-detail">
                <span>OTS Classes Attend</span>
                <span className="student-detail-description">20</span>
              </div>
              <div className="student-detail">
                <span>Performance</span>
                <span className="student-detail-description">50%</span>
              </div>
            </div>
            <div className="" style={{ display: "flex", justifyContent: "center", marginBottom: 5, position: "absolute", bottom: 0, width: "100%" }}>
              <div className="footer-button" style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  style={{
                    backgroundColor: "#ECEEEE",
                    borderColor: "#ECEEEE",
                    color: "black",
                    boxShadow: "none"
                  }}
                  type="primary"
                  htmlType="submit"
                  className="login-button"
                  onClick={(e) => { e.stopPropagation(); openZoom(); }}
                >
                  <img
                    style={{ width: 10, marginRight: 7 }}
                    src={ZoomIcon}
                    alt=""
                  />
                  Zoom Video
                </Button>
              </div>
              <div className="footer-button" style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  style={{
                    backgroundColor: "#ECEEEE",
                    borderColor: "#ECEEEE",
                    color: "black",
                    boxShadow: "none",
                    width: 100
                  }}
                  type="primary"
                  htmlType="submit"
                  className="login-button"
                  onClick={(e) => { e.stopPropagation(); doubleClick() }}
                >
                  <img
                    style={{ width: isDoubleClicked ? 13 : 10, marginRight: 7 }}
                    src={isDoubleClicked ? MicOffIcon : MicOnIcon}
                    alt=""
                  />
                  <span style={{ width: 50 }}>{isDoubleClicked ? "Mute" : "Unmute"}</span>
                </Button>
              </div>
            </div>
          </div>
        </div>

      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    zoomModal: (data) => dispatch(zoomModal(data)),
  };
};

export default connect(null, mapDispatchToProps)(StudentInfoDialog);
