import React from 'react';
import { Card } from 'antd';

import { ReactComponent as Visibility } from '../../assets/img/icons/visibility-on.svg';
import { ReactComponent as Dollar } from '../../assets/img/icons/dollar.svg';

const { Meta } = Card;

const YourTopThreeCard = (props) => {
  const { card } = props;

  return (
    <div>
      <Card size="size"
        className="card-cover-main"
        cover={
          <div className="card-cover">
            <div className="card-cover-container" style={{ zIndex: 1 }}>
              {card.name === 'mostViewed' ? (
                <Visibility className="visibility-icon" />
              ) : (
                <Dollar style={{ marginBottom: 3 }} className="dollar-icon" />
              )}
              <span className="card-cover-count">{card.name === 'mostViewed' ? card.card.paidViews : card.card.earnings.toFixed(2)}</span>
            </div>
            <span style=
              {{
                zIndex: 1, position: "absolute", top: 50,
                left: 0, right: 0, bottom: 0,
                display: 'flex', justifyContent: 'center',
                alignItems: 'center', fontSize: 14,
                fontWeight: 600, color: '#ffffff'
              }}
            >
              {card.name === 'mostViewed' ? 'MOST VIEWED' : 'MOST EARNED'}
            </span>
            <div className="card-cover-overlay"
              style={{
                backgroundColor: "#00000082", position: 'absolute',
                opacity: 0.7, width: '100%', height: '175px',
                borderTopLeftRadius: 10, borderTopRightRadius: 10
              }}
            >
            </div>
            <img className="card-cover-image" alt="card-image"
              src={card.card.class.imageURL} height="175px"
              style={{ objectFit: "cover", width: '100%' }} />
          </div>
        }
      >
        <Meta className="card-cover-detail"
          title={card.card.class.name}
          description={`${card.card.class.category} · ${card.card.class.duration / 60} min`} />
      </Card>
    </div>
  );
}

export default YourTopThreeCard;